
import SousMenu from './SousMenu.js';
import React, { Component } from 'react';
import { Row, Col, Card } from 'antd';
import env from '../commun/config.js';
import border from '../../images/border.png';
import SousMenuVoca from './SousMenuVoca.js';
import { Helmet } from 'react-helmet';
import Ad from '../commun/AdSense.js';
import { Link } from 'react-router-dom';
import { readListErrors } from '../commun/localStorage.js';
import Liens from './Liens.js';
import ClassementVocabulaire from '../commun/ClassementVocabulaire.js';

export default class PhrasesEnglish extends Component {

    constructor()
    {
        super();

    }
    choixMessageListe = (nom, mot, langue) => {
        let resultat = readListErrors(nom);
        if (resultat.info.length === 0) {
            return <div>{"Tu n'as pas encore de " + mot + " à revoir."}</div>

        }
        else {

            let motLangue = resultat.info.map(mot => langue === 'f' ? mot.f : mot.a);
            return <div>{motLangue.map((mot, i) => <span key={i + langue} className="margeDroite">{mot.length > 12 ? mot.substring(0, 12) + '...' : mot}</span>)}<div className="grandeFont centre"><Link to={nom + '/9999'}>Revoir ces phrases</Link></div></div>

        }

    }
    render() {
        return <div>
                    <Helmet>
          <title>Traduire des phrases en français</title>
          <meta name="description" content="Des tests pour s'entrainer à traduire de petites phrases de l'anglais vers le français."/>
                 </Helmet>
          
          
            <div className="centre"><img src={border} alt="bordure"></img></div>
            <h1 className="titre centre">Traduire des phrases en français</h1>
<div className="centre"><img src={border} alt="bordure"></img></div>
            <Row gutter={{ md: 16 }} className="margeHaut">

                <Col md={24}>
                    <h2>Traduire de petites phrases de l'anglais vers le français</h2>
                    <p>Dans ces tests il faut traduire de courtes phrases de l'anglais vers le français. La ponctuation en fin de phrase n'est pas obligatoire.
                    </p> 
                     <p>Tu peux trouver toutes les phrases à traduire de ces tests dans notre <Link to='phrase-francais'>lexique des phrases à traduire de l'anglais vers le français</Link>.</p>
                <p>Si tu veux faire les tests dans l'autre sens, tu peux aller sur notre page <Link to='/test-phrases'>français vers anglais</Link>. </p>

           
                </Col>
            </Row>
            <Row gutter={{ md: 16 }}>
                <Col md={12}>
                 <SousMenuVoca type='phrase' langue='anglais' nom={env.obtenirNomComplet('anglais', 'phrase')} titre="Phrases anglais->français"></SousMenuVoca>

                    <Card className="margeHaut20 fondCard">
                      <div><b>Liste des phrases que tu ne maitrises pas encore.</b></div>
                        {this.choixMessageListe(env.obtenirNomComplet('anglais', 'phrase'), 'phrases', 'f')}
                    </Card>
                </Col>
            

            </Row>

            <ClassementVocabulaire type='3'nom={env.obtenirNomComplet('anglais', 'phrase')} ></ClassementVocabulaire>

        <Ad></Ad>
           


           
            <div className="centre"><img src={border} alt="bordure"></img></div>
            <Liens></Liens>
           
        </div>
    }
}