function melangeLettres(mots, type) {
    
    let  tabElement;
        if (type === 'phrase')
        {
            tabElement = mots.split(' ');
        }
        else
        {
            tabElement = mots.split('');
        }

        let tabLettreMelange;
        let nbElement;

        do {
            tabLettreMelange = [];
            nbElement = tabElement.length;
            for (let index = 0; index < nbElement; index++) {
                let nb = Math.floor(Math.random() * tabElement.length);
                tabLettreMelange.push(tabElement[nb]);
                tabElement.splice(nb, 1);
            }
        }
        while (tabLettreMelange.toString() === mots.toString());

        return tabLettreMelange;
    }
function supprimerTo(motReponse)
{

    var posTo = motReponse.indexOf("to ");
    let verbe = false;
    if (posTo === 0)
    {
        motReponse = motReponse.substring(3);
        verbe = true;
    }
    return {motReponse, verbe};
}

    export {melangeLettres, supprimerTo};
