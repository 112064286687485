import React, { Component } from 'react';
import {Button, Input, Col, Row} from 'antd';

export default class Saisie extends Component {
   
    constructor(props) {
        super(props);
        this.textInput = React.createRef();
        this.state = {mot: ''};
    }

    handleChange = (event) => {
        this.setState({mot: event.target.value});
      }
    
    clickBouton = () =>
    {
        this.props.verifierReponse(this.state.mot.toLocaleLowerCase());
    }

    componentDidMount() {
        this.textInput.current.focus();
      }

    render()
    {
        return (<div><Row className="animationSaisie"><Col xs={{span:10, offset: 1}} md={{span:10, offset: 6}}><Input type="text"  autoComplete="off" autoCorrect="off" autoCapitalize="off" 
        spellCheck="false"  value={this.state.mot} ref={this.textInput} onChange={this.handleChange} onPressEnter={this.clickBouton}></Input>
        </Col><Col xs={{span:3, offset: 1}} ><Button  type="primary" onClick={this.clickBouton}>OK</Button></Col><Col xs={{span:3, offset: 1}} ><Button  type="primary" onClick={this.props.clickAnnuler}>Annuler</Button></Col></Row></div>)
    }
}