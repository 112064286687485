import React, { Component } from 'react';

import { Helmet } from 'react-helmet';
import { addGame } from '../../components/commun/localStorage';
import DataAccess from '../../data/DataAccess';
import {Redirect } from 'react-router-dom';
//import Mots from './Mots';
import QuestionFerme from './QuestionFerme';
import QuestionOuverte from './QuestionOuverte';
import Resultat from '../../components/commun/Resultat';
import Statistique from '../../components/commun/Statistique';
import env from '../../components/commun/config';
import FinEtape from '../concours/FinEtape';
import Audio from '../commun/Audio';
import audio from '../../images/boutonAudio.JPG';


export default class JeuMemoire extends Component {
    constructor(props) {
        super();
        this.id = parseInt(props.match.params.id);
        this.stop = false;
        this.data = new DataAccess(env.nomMemoire, this.id)
        this.dataJeu = this.data.obtenirDonneesJeu();
        if (this.dataJeu == null) {
            this.stop = true;
        }
        else {
            this.nom = env.nomMemoire;

            this.state = {
                typeEcran: 'image',
                finJeu: false,
                audioDemmare : true
            }
            this.tabResult = new Array(this.dataJeu.info.questions.length).fill(1000);
            this.stat = new Statistique(this.dataJeu.info.questions.length);
            addGame(this.nom, this.id);
   
        }
    }


    finAffichage = () => {
        this.setState({ typeEcran: 'question' });
    }

    resultatQuestion = (noQuestion, resultat) => {
        this.tabResult[noQuestion] = resultat;
        if (!resultat)  this.stat.ajouterFaute();
        if (this.tabResult.find((nb) => nb === 1000) === undefined) {
            window.setTimeout(() => this.setState({ finJeu: true }), 1500);
        }

    }

    // gestionAffichage = () => {
    //     switch (this.state.typeEcran) {
    //         case 'image':
    //             return <Mots  mots={this.dataJeu.info.mots} time={this.dataJeu.temps} finAffichage={this.finAffichage}></Mots>

    //         case 'question':
    //             return (<div className="fontMoyenne grandEspaceHaut"> {this.dataJeu.info.questions.map((test, i) => test.typeQuestion === 'ferme' ? <QuestionFerme key={i} noQuestion={i} resultatQuestion={this.resultatQuestion} test={test}></QuestionFerme> : <QuestionOuverte resultatQuestion={this.resultatQuestion} noQuestion={i} key={i} test={test}></QuestionOuverte>)}</div>);
    //         default:
    //             return <div>Erreur !!</div>
    //     }
    // }
    audioDepart = () =>
    {
       // this.setState({audioDemmare : false});
    }

choixJeu = () =>
{
    return <div>{this.dataJeu.info.texte !== undefined ? 
    <div className="texteMemoire"  dangerouslySetInnerHTML={{ __html: this.dataJeu.info.texte }}></div>: 
    this.dataJeu.info.image !== undefined ? 
    <div className="imageMemoire"><img className="img-responsive" src={"https://evalquiz.com/imageTest/" + this.dataJeu.info.image} alt={this.dataJeu.info.image}></img></div>
    : <div className="audioVocal"><Audio audioDepart={this.audioDepart} depart={false} nomFichier={'/audio/' + this.dataJeu.info.son}></Audio></div>}</div>
}


    render() {
        if (this.stop) return (<Redirect to="/"></Redirect>);
        return (<div>  <Helmet>
            <title>Test d'anglais : comprendre un texte à l'écrit, à l'oral ou un dessin en anglais</title>
            <meta name="description" content="Exercices de compréhension d'un texte écrit, d'une image ou d'un texte lu en anglais." />
        </Helmet>
        {!this.state.finJeu ? <div><h1>Compréhension - Anglais</h1>{this.dataJeu.info.son !== undefined && <p>Cliquez sur le bouton Play <img src={audio} alt="bouton audio"></img> du lecteur audio pour écouter la phrase.</p>}{this.choixJeu()}
        <div className="fontMoyenne grandEspaceHaut"> {this.dataJeu.info.questions.map((test, i) => test.typeQuestion === 'ferme' ? <QuestionFerme key={i} noQuestion={i} resultatQuestion={this.resultatQuestion} test={test}></QuestionFerme> : <QuestionOuverte resultatQuestion={this.resultatQuestion} noQuestion={i} key={i} test={test}></QuestionOuverte>)}</div></div>
        :  (this.data.concours ? <FinEtape nom={this.nom} donneesJeu={this.data.jeu} nbFautes={this.stat.resultat.nbFautes}></FinEtape>:<Resultat nom={this.nom} id={this.id} statistique={this.stat} donnees={this.data}></Resultat>)}</div>);
    }
}
