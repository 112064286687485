import React, { Component } from 'react';
import { motAideEnCours, lettresDebut } from '../commun/logiqueTraduction';
import { Button } from 'antd';

function Compteur(props) {
    return <div>Question : <span className="moyenneFont couleurResultat">{props.noQuestion} / {props.nbQuestion}</span></div>
}



export default class AideDebut extends Component {
    constructor(props) {
        super();
        this.nbLettreAide = props.nbLettre;
        this.noMotAide = -1;
        this.lastPhrase = [];
        this.lettresDebut = lettresDebut(props.phrase, this.nbLettreAide);
        this.state = {
            lettresDebut: this.lettresDebut,
            afficheBouton: true
        }
    }

    nouvelleLettre = () => {
        this.nbLettreAide++;
        if (this.nbLettreAide === 2) {
            this.setState({
                afficheBouton: false
            });
        }
        this.lettresDebut = lettresDebut(this.props.phrase, this.nbLettreAide);
        this.setState({
            lettresDebut: motAideEnCours(this.lettresDebut, this.noMotAide)
        });
    }

    updateAide = (phrase) => {
        const newPhrase = phrase.split(' ');
        const l = newPhrase.length;
        if (l > this.lastPhrase.length) {
            this.noMotAide = l - 1;
        }
        else {
            this.noMotAide = -1;
            for (let index = 0; index < newPhrase.length; index++) {
                if (index < this.lastPhrase.length && newPhrase[index] !== this.lastPhrase[index]) {
                    this.noMotAide = index;
                    break;
                }
            }
            if (this.noMotAide === -1) 
            {
             this.noMotAide = - 1;
            }

        }
        this.lastPhrase = newPhrase;
        this.setState({
            lettresDebut: motAideEnCours(this.lettresDebut, this.noMotAide)
        });


    }

    render() {
        return <div>
            <p className="espaceMot" dangerouslySetInnerHTML={{ __html: this.state.lettresDebut }}></p>{this.state.afficheBouton && <Button onClick={this.nouvelleLettre}>Voir une autre lettre</Button>}
        </div>

    }
}

export { Compteur };