import React, { Component } from 'react';
import DataAccess from '../../data/DataAccess';
import { message, Button } from 'antd';
import MotDefinition from './MotDefinition';
import Saisie from './Saisie';
import Logique from './Logique';
import Regle from './Regle';
import Resultat from '../../components/commun/Resultat';
import Statistique from '../../components/commun/Statistique';
import { withRouter, Redirect } from 'react-router-dom';

import { addGame } from '../../components/commun/localStorage';
import { Helmet } from 'react-helmet';
import CompteRebours from '../commun/CompteRebours';
import env from '../../components/commun/config';
import FinEtape from '../concours/FinEtape';



class ListeMots extends Component {
    constructor(props) {
        super(props);
        this.id = parseInt(this.props.match.params.id);
        this.stop = false;
        this.perdu = false;
        this.data = new DataAccess(env.nomListe, this.id);
        this.donneeJeu = this.data.obtenirDonneesJeu();
        if (isNaN(this.id) ||  this.donneeJeu == null) {
            this.stop = true;
        }
        else {
            this.nom = env.nomListe;
            this.temps =  this.donneeJeu.temps;
            this.motEnCours = 0;

            this.aide = false;
            this.listeModifie = null;
      

            let listeQuestions = [];
            for (const info of this.donneeJeu.liste) {
                let tabLettresUtilisateur = Array(info.mot.length).fill('');
                let tabLettresMot = info.mot.split('');
                listeQuestions.push({ tabLettresUtilisateur, tabLettresMot, definition: info.definition, trouve: false });
            }

            this.state = {
                afficheSaisie: false,
                listeQuestions,
                finJeu: false,
                nbAide: 1
            }
            this.dureeJeu = Date.now();
            this.attenteVictoire = false;
            this.stat = new Statistique(this.donneeJeu.liste.length);
            addGame('listeMots', this.id);
      
        }
    }

    afficheSaisie = (numero) => {
        this.motEnCours = numero;
        if (this.aide) {
            let nouvelleListe = [...this.state.listeQuestions];
            Logique.modifierLettres(nouvelleListe, this.donneeJeu.liste[this.motEnCours].mot, this.motEnCours);
          
            setTimeout(this.finMessageReussi, 1500);
            this.setState({ listeQuestions: nouvelleListe, afficheSaisie: false, nbAide: this.state.nbAide - 1 }, this.supprimerMot);
            this.listeModifie = [...nouvelleListe];
            this.aide = false;
            return;
        }
        if (this.state.afficheSaisie) {
            this.setState({ afficheSaisie: false }, this.nouveauAffichage);
        }
        else {
            this.setState({ afficheSaisie: true });
        }

    }

    motAide = () => {
        this.aide = true;
    }

    nouveauAffichage = () => {
        this.setState({ afficheSaisie: true });
    }

    verifierReponse = (mot) => {
        if (mot === this.donneeJeu.liste[this.motEnCours].mot) {
            this.attenteVictoire = true;
            message.success("Well done!", 1.5, this.finMessageReussi);
            let nouvelleListe = [...this.state.listeQuestions];
            Logique.modifierLettres(nouvelleListe, mot, this.motEnCours);

            this.setState({ listeQuestions: nouvelleListe, afficheSaisie: false },this.supprimerMot);
            this.listeModifie = [...nouvelleListe];
          

        }
        else {
            message.error("Sorry, that is incorrect.", 2);
            this.stat.ajouterFaute();
        }
    }
    supprimerMot = () =>
    {
        Logique.supprimerMotsTrouve(this.listeModifie);
    }

    finMessageReussi = () => {
   
      this.setState({ listeQuestions: this.listeModifie });

        if (Logique.estGagnant(this.state.listeQuestions)) {      
            this.dureeJeu = Date.now() - this.dureeJeu;
            this.setState({ finJeu: true });
        }
    }

    clickAnnuler = () => {
        this.setState({ afficheSaisie: false });
    }
    finTimer = () => {
        if (!(this.listeModifie != null && Logique.estGagnant(this.listeModifie))) this.perdu = true;
        this.setState({ finJeu: true });
    }


    render() {
        if (this.stop) return (<Redirect to="/"></Redirect>);
        return <div>
            <Helmet>
                <title>Test de vocabulaire : la pyramide des mots anglais</title>
                <meta name="description" content="Un jeu de vocabulaire facile et amusant où il faut retrouver des mots en anglais à partir de leur définition, un mélange de slam et de mots fléchés." />
            </Helmet>
            {this.state.finJeu ? 
                 (this.data.concours ? <FinEtape nom={this.nom} donneesJeu={this.data.jeu} perdu={this.perdu}></FinEtape>:<Resultat nom={this.nom} id={this.id} statistique={this.stat} donnees={this.data}></Resultat>):
                    <div><div><span className="titreJeu">La pyramide des mots anglais</span><Regle></Regle></div>{ this.state.listeQuestions.map((info, i) => <div key={i} className={info.trouve ? 'supprimerMot' : ''}>
                        <MotDefinition afficheSaisie={this.afficheSaisie} numero={i} definition={info.definition} mot={info.tabLettresUtilisateur}></MotDefinition>

                    </div>) } <CompteRebours temps={this.temps} finTimer={this.finTimer}></CompteRebours>
            {this.state.afficheSaisie ? <div><h2 className="centre">Votre réponse pour</h2><p className="centre">{this.state.listeQuestions[this.motEnCours].definition}</p><Saisie verifierReponse={this.verifierReponse} clickAnnuler={this.clickAnnuler}></Saisie></div> :
                this.state.nbAide !== 0 && <div className="grandEspaceHaut"><p >Vous avez le droit à {this.state.nbAide + ' ' + (this.state.nbAide === 1 ? 'aide' : 'aides')}, choisissez le mot que vous ne trouvez pas après avoir cliqué sur le bouton Aide.</p>
                    <Button type="primary" onClick={this.motAide}>Aide</Button></div>}</div>}
        </div>
    }
}

export default withRouter(ListeMots);