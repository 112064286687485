import React, { Component } from 'react';
import {Input, Button} from 'antd';
import Saisie from '../commun/Saisie';
import Logique from './Logique';




export default class Question extends Component {

    constructor(props) {
        super();
        this.state = { mot: '' };
        this.textInput = React.createRef();
    }

    handleChange = (event) => {
   
            this.setState({mot: event.target.value});
        
    }

    clickBouton = () => {
        this.props.verifierReponse(this.state.mot);
        this.setState({mot: ''});
        
     
    }

    

    componentDidMount() {
        this.textInput.current.focus();
    }


    saisieOuTexte = (texte) => {
        if (texte === 'AREMPLIR') {
            return <b>???</b>
        }
        else {
            return texte;
        }
    }

 
 
    render() {
        return <div>

            <div className="vi" ><table>
                <thead>
                <tr>
                    <th>Infinitif sans to</th>
                    <th>Prétérit</th>
                    <th>Participe passé</th>
                    <th>Traduction</th>
                </tr></thead>
            <tbody>
                <tr>
                    <td>{this.saisieOuTexte(this.props.verbe.base)}</td>
                    <td>{this.saisieOuTexte(this.props.verbe.past_simple)}</td>
                    <td>{this.saisieOuTexte(this.props.verbe.past_participle)}</td>
                    <td>{this.saisieOuTexte(this.props.verbe.translation)}</td>
                </tr></tbody>
            </table>
            <div className='centre margeHaut30'>
            <div><Input className="saisie" type="text" autoComplete="off" autoCorrect="off" autoCapitalize="off"
        allowClear={true}    spellCheck="false" value={this.state.mot} ref={this.textInput} onPressEnter={this.clickBouton} onChange={this.handleChange}></Input>
        <Button className="margeGauche" type="primary" onClick={this.clickBouton}>OK</Button></div>
            </div>
            </div>
        </div>
    }
}