import React, { Component } from 'react';
import Prenom from '../commun/Prenom';
import { verifierStatus } from '../../components/commun/Utilitaire';
import { withRouter, Link, Redirect} from 'react-router-dom';
import { addFirstName, readFirstName } from '../../components/commun/localStorage';
import SousMenuVoca from '../../components/principal/SousMenuVoca';


class Resultat extends Component {

    constructor(props)
    {
        super();
        this.dejaFait = false;
        this.state = {
            redirection: false,
            prenom: "",
         
            prenomVisible: false
        }
    
        this.prochain = props.donnees.obtenirProchainJeu(props.id, props.nom);
        this.cheminProchain = null;
      
    }

    typeClassement()
    {
        if (this.props.langue === 'anglais')
        {
            if (this.props.type === 'vocabulaire')
            {
                return 1;
            }
            else
            {
                return 3;
            }
        }
        if (this.props.langue === 'francais')
            {
                if (this.props.type === 'vocabulaire')
                {
                    return 2;
                }
                else
                {
                    return 4;
                }
            }
    }

    componentDidMount = () => {
      
        if (this.props.listeFaute.length ===0 && this.props.id !== 9999) {
            const prenom = readFirstName();
            if (prenom === null) {
                this.setState({
                    prenomVisible: true
                })
            }
            else {

                this.envoyerMessage(prenom);

            }
        }
    }
 
    callbackPrenom = (prenom) => {
        prenom = prenom + "$" + Math.floor(Math.random() * 100000);
        addFirstName(prenom);

        this.envoyerMessage(prenom);
        this.setState({
            prenomVisible: false
        })


    }

    async envoyerMessage(prenom) {
        let url = new URL(process.env.REACT_APP_URL_FINVOCABULAIRE);
      
       
        const reponse = await fetch(url + "?prenom=" + prenom + "&id=" + this.props.id + "&type=" + this.typeClassement() , {
            method: "GET",
        })
        if (!verifierStatus(reponse.status)) {
            return;
        }
        if (reponse.ok) {
            const res = await reponse.json();

            this.setState({
        
                prenom
            })

        }
        else {
            alert("Désolé, il y a un problème.")
            window.location.href = "/"
        }

    }

  


    render() {
        if (this.state.redirection) {
            return <Redirect to={this.cheminProchain}></Redirect>
        }
        else {
 
        return <div>{this.props.id === 9999 ?  this.props.listeFaute.length ===0 ? 
          <div className='centre'><p className="moyenneFont violet">Bravo ! Tu as terminé tes révisions</p>
          <p className='tresGrandeFont'>😉</p>
          <p>Tu peux refaire les tests que tu n'as pas réussi.</p></div>:
           <div className='centre'>
          <p className="moyenneFont violet">Tu dois encore continuer à revoir les mots ou expressions que tu ne connais pas.</p>
          <p className='tresGrandeFont'>😢</p>
        </div>
        :  <div className="centre">
              {this.state.prenomVisible && <Prenom callbackPrenom={this.callbackPrenom}></Prenom>}
            <h1 className="couleurResultat">
            <div>Résultat</div>
        <div className="couleurResultat">{this.props.titre}</div></h1>

          {this.props.listeFaute.length ===0 ? 
          <div>
          <p className="moyenneFont violet">Bravo {this.state.prenom} ! Tu as validé ce test</p>
          <p className='tresGrandeFont'>😉</p>
          <p>Ton prénom est inscrit au tableau d'honneur de la page d'accueil</p></div>
          :
           <div>
            <p>Malheureusement tu n'as pas validé ce test</p>
            <p className='tresGrandeFont'>😢</p>
           <p>Tu dois recommencer tout le test pour le valider. 🥺</p>
           <p>Il est préférable de ne pas le recommencer tout de suite pour faciliter la mémorisation.</p>
            <div>Nombre de fautes : {this.props.listeFaute.length}</div>
           </div>}
        </div>}
 
            {this.props.listeFaute.length !== 0 && <div>
                <h2>Ce que tu dois revoir en priorité</h2>
                {this.props.listeFaute.map((texte, i) => <div key={i}><span>{texte.f}</span> (<span>{texte.a}</span>)</div>)}
            </div>}
       
          
            <SousMenuVoca className="margeHaut" nom={this.props.nom} langue={this.props.langue} type={this.props.type} titre="Exercices du même type" supprimer={this.props.id}></SousMenuVoca>
            <p className="fontMoyenne centre"><Link to='/'>Retour à la page d'accueil</Link></p>
  </div>
       
        }
    }

}

export default withRouter(Resultat);