export default class LogiqueQcm
{
    static construirePhrase(phrase)
    {
        let debut = true;
        let phraseHtml = '';
        let mot = '';
        for (let index = 0; index < phrase.length; index++) {
           
            let c = phrase.charAt(index);
            if (c !== '@')
            {
                phraseHtml+= c;
                if (!debut)
                {
                    mot += c;
                }
            }
            else
            {
                if (debut)
                {
                    phraseHtml += '<b class="motCache">';
                    debut= false;
                }
                else
                {
                    phraseHtml += '</b>';
                    debut= true;
                }
            }
            
        }
        return {phraseHtml, mot};
    }
}