import React from 'react';
import DataVocabulaire from '../data/dataVocabulaire';
import { Row, Col } from 'antd';
import {Helmet } from 'react-helmet';

import { verbesIrreguliers } from '../data/dataVerbesIrreguliers';


export default function Vocabulaire2() {

    function creerGoupe()
    {
      let tab = [];
      let tabNiveau = [];
      for (let index = 1; index < 5 ; index++) {
          tab = DataVocabulaire.filter(x => x.niveau === index);
          tabNiveau.push(tab);
      }
 
      return tabNiveau;
    }
  
    function titre(no)
    {
      let msg = 'Vocabulaire ';
      switch (no)
      {
          case 0 : return msg + 'très facile';
          case 1 : return msg + 'facile';
          case 2 : return msg + 'difficile';
          case 3 : return msg + 'très difficile';
      }
    }

    function construction(jeu,i) {
      
        return <div><h3  key={i+1000000} className="couleurLien">{jeu.titreF}</h3>
        <div>{jeu.info.map((mots, j) => <div  key={(i*20)+j} className="motsVocaEspace">{mots.f} : <div className="motsVoca">{mots.a} {mots.indicA !== undefined && <div className="couleurIndice">{mots.indicA}</div>} </div></div>)}
        </div></div>
    }

    return <div> 
           <Helmet>
          <title>Liste de vocabulaire anglais vers français</title>
          <meta name="description" content="Liste de vocabulaire anglais vers français pour apprendre ou réviser des mots et des verbes en anglais, la liste peut être imprimée en PDF."/>
            </Helmet>
        <h1>Liste de mots anglais avec traduction française</h1>
        <p>Vous pouvez retrouver sur cette page tous les mots qui sont contenus dans <a href="https://anglais.evalquiz.com/test-vocabulaire-anglais/vocabulaire/francais/1">les tests de vocabulaire</a> de ce site. Si vous voulez obtenir un PDF de cette liste de vocabulaire il suffit d'imprimer la page puis de choisir "Save as PDF" dans le choix de l'imprimante.</p>
        <p>Si vous avez des questions, des améliorations à proposer, vous pouvez consulter notre <a href="https://evalquiz.com/home/faq">FAQ</a> ou nous envoyer un message par <a href="https://twitter.com/evalquiz">Twitter</a>.</p>
        {creerGoupe().map((groupe, i) =>
        <React.Fragment key={i}><h2>{titre(i)}</h2>   
       <Row  className="margeHaut">  {groupe.map((jeu, k) => <Col className="espaceCol"  key={((i+1)*10)+k} md={12}>{construction(jeu, ((i+1)*10000 + (k*20)))}
    </Col>)}</Row></React.Fragment>)}
    
         
  
    </div>
}