let verbesIrreguliers = [
    {
        base: "be",
        past_simple: "was/were",
        past_participle: "been",
        translation: "être"
    },
    {
        base: "beat",
        past_simple: "beat",
        past_participle: "beaten",
        translation: "battre"
    },
    {
        base: "become",
        past_simple: "became",
        past_participle: "become",
        translation: "devenir"
    },
    {
        base: "begin",
        past_simple: "began",
        past_participle: "begun",
        translation: "commencer"
    },
    {
        base: "bend",
        past_simple: "bent",
        past_participle: "bent",
        translation: "plier"
    },
    {
        base: "bet",
        past_simple: "bet",
        past_participle: "bet",
        translation: "parier"
    },
    {
        base: "bid",
        past_simple: "bid",
        past_participle: "bid",
        translation: "offrir"
    },
    {
        base: "bite",
        past_simple: "bit",
        past_participle: "bitten",
        translation: "mordre"
    },
    {
        base: "bleed",
        past_simple: "bled",
        past_participle: "bled",
        translation: "saigner"
    },
    {
        base: "blow",
        past_simple: "blew",
        past_participle: "blown",
        translation: "souffler"
    },
    {
        base: "break",
        past_simple: "broke",
        past_participle: "broken",
        translation: "casser"
    },
    {
        base: "breed",
        past_simple: "bred",
        past_participle: "bred",
        translation: "élever"
    },
    {
        base: "bring",
        past_simple: "brought",
        past_participle: "brought",
        translation: "apporter"
    },
    {
        base: "broadcast",
        past_simple: "broadcast",
        past_participle: "broadcast",
        translation: "diffuser"
    },
    {
        base: "build",
        past_simple: "built",
        past_participle: "built",
        translation: "construire"
    },
    {
        base: "burn",
        past_simple: "burned/burnt",
        past_participle: "burned/burnt",
        translation: "brûler"
    },
    {
        base: "burst",
        past_simple: "burst",
        past_participle: "burst",
        translation: "éclater"
    },
    {
        base: "buy",
        past_simple: "bought",
        past_participle: "bought",
        translation: "acheter"
    },
    {
        base: "cast",
        past_simple: "cast",
        past_participle: "cast",
        translation: "jeter"
    },
    {
        base: "catch",
        past_simple: "caught",
        past_participle: "caught",
        translation: "attraper"
    },
    {
        base: "choose",
        past_simple: "chose",
        past_participle: "chosen",
        translation: "choisir"
    },
    {
        base: "cling",
        past_simple: "clung",
        past_participle: "clung",
        translation: "s'accrocher"
    },
    {
        base: "come",
        past_simple: "came",
        past_participle: "come",
        translation: "venir"
    },
    {
        base: "cost",
        past_simple: "cost",
        past_participle: "cost",
        translation: "coûter"
    },
    {
        base: "creep",
        past_simple: "crept",
        past_participle: "crept",
        translation: "ramper"
    },
    {
        base: "cut",
        past_simple: "cut",
        past_participle: "cut",
        translation: "couper"
    },
    {
        base: "deal",
        past_simple: "dealt",
        past_participle: "dealt",
        translation: "traiter"
    },
    {
        base: "dig",
        past_simple: "dug",
        past_participle: "dug",
        translation: "creuser"
    },
    {
        base: "dive",
        past_simple: "dived/dove",
        past_participle: "dived",
        translation: "plonger"
    },
    {
        base: "do",
        past_simple: "did",
        past_participle: "done",
        translation: "faire"
    },
    {
        base: "draw",
        past_simple: "drew",
        past_participle: "drawn",
        translation: "dessiner"
    },
    {
        base: "dream",
        past_simple: "dreamed/dreamt",
        past_participle: "dreamed/dreamt",
        translation: "rêver"
    },
    {
        base: "drive",
        past_simple: "drove",
        past_participle: "driven",
        translation: "conduire"
    },
    {
        base: "drink",
        past_simple: "drank",
        past_participle: "drunk",
        translation: "boire"
    },
    {
        base: "dwell",
        past_simple: "dwelt/dwelled",
        past_participle: "dwelt/dwelled",
        translation: "habiter"
    },
    {
        base: "eat",
        past_simple: "ate",
        past_participle: "eaten",
        translation: "manger"
    },
    {
        base: "fall",
        past_simple: "fell",
        past_participle: "fallen",
        translation: "tomber"
    },
    {
        base: "feed",
        past_simple: "fed",
        past_participle: "fed",
        translation: "nourrir"
    },
    {
        base: "feel",
        past_simple: "felt",
        past_participle: "felt",
        translation: "sentir"
    },
    {
        base: "fight",
        past_simple: "fought",
        past_participle: "fought",
        translation: "se battre"
    },
    {
        base: "find",
        past_simple: "found",
        past_participle: "found",
        translation: "trouver"
    },
    {
        base: "fit",
        past_simple: "fit",
        past_participle: "fit/fitted",
        translation: "adapter"
    },
    {
        base: "flee",
        past_simple: "fled",
        past_participle: "fled",
        translation: "fuir"
    },
    {
        base: "fling",
        past_simple: "flung",
        past_participle: "flung",
        translation: "jeter violemment"
    },
    {
        base: "fly",
        past_simple: "flew",
        past_participle: "flown",
        translation: "voler"
    },
    {
        base: "forbid",
        past_simple: "forbade/forbad",
        past_participle: "forbidden",
        translation: "interdire"
    },
    {
        base: "forecast",
        past_simple: "forecast",
        past_participle: "forecast",
        translation: "prévoir"
    },
    {
        base: "forget",
        past_simple: "forgot",
        past_participle: "forgotten",
        translation: "oublier"
    },
    {
        base: "forgive",
        past_simple: "forgave",
        past_participle: "forgiven",
        translation: "pardonner"
    },
    {
        base: "freeze",
        past_simple: "froze",
        past_participle: "frozen",
        translation: "geler"
    },
    {
        base: "get",
        past_simple: "got",
        past_participle: "got/gotten",
        translation: "obtenir"
    },
    {
        base: "give",
        past_simple: "gave",
        past_participle: "given",
        translation: "donner"
    },
    {
        base: "go",
        past_simple: "went",
        past_participle: "gone",
        translation: "aller"
    },
    {
        base: "grind",
        past_simple: "ground",
        past_participle: "ground",
        translation: "moudre"
    },
    {
        base: "grow",
        past_simple: "grew",
        past_participle: "grown",
        translation: "grandir"
    },
    {
        base: "hang",
        past_simple: "hung",
        past_participle: "hung",
        translation: "accrocher"
    },
    {
        base: "have",
        past_simple: "had",
        past_participle: "had",
        translation: "avoir"
    },
    {
        base: "hear",
        past_simple: "heard",
        past_participle: "heard",
        translation: "entendre"
    },
    {
        base: "hide",
        past_simple: "hid",
        past_participle: "hidden",
        translation: "cacher"
    },
    {
        base: "hit",
        past_simple: "hit",
        past_participle: "hit",
        translation: "frapper"
    },
    {
        base: "hold",
        past_simple: "held",
        past_participle: "held",
        translation: "tenir"
    },
    {
        base: "hurt",
        past_simple: "hurt",
        past_participle: "hurt",
        translation: "blesser"
    },
    {
        base: "keep",
        past_simple: "kept",
        past_participle: "kept",
        translation: "garder"
    },
    {
        base: "kneel",
        past_simple: "knelt/kneeled",
        past_participle: "knelt/kneeled",
        translation: "s'agenouiller"
    },
    {
        base: "know",
        past_simple: "knew",
        past_participle: "known",
        translation: "savoir"
    },
    {
        base: "lay",
        past_simple: "laid",
        past_participle: "laid",
        translation: "poser"
    },
    {
        base: "lead",
        past_simple: "led",
        past_participle: "led",
        translation: "mener"
    },
    {
        base: "lean",
        past_simple: "leant/leaned",
        past_participle: "leant/leaned",
        translation: "s'appuyer/se pencher"
    },
    {
        base: "leap",
        past_simple: "leapt/leaped",
        past_participle: "leapt/leaped",
        translation: "sauter"
    },
    {
        base: "learn",
        past_simple: "learnt/learned",
        past_participle: "learnt/learned",
        translation: "apprendre"
    },
    {
        base: "leave",
        past_simple: "left",
        past_participle: "left",
        translation: "partir/laisser"
    },
    {
        base: "lend",
        past_simple: "lent",
        past_participle: "lent",
        translation: "prêter"
    },
    {
        base: "let",
        past_simple: "let",
        past_participle: "let",
        translation: "laisser"
    },
    {
        base: "lie",
        past_simple: "lay",
        past_participle: "lain",
        translation: "être allongé"
    },
    {
        base: "light",
        past_simple: "lit/lighted",
        past_participle: "lit/lighted",
        translation: "allumer"
    },
    {
        base: "lose",
        past_simple: "lost",
        past_participle: "lost",
        translation: "perdre"
    },
    {
        base: "make",
        past_simple: "made",
        past_participle: "made",
        translation: "fabriquer/faire"
    },
    {
        base: "mean",
        past_simple: "meant",
        past_participle: "meant",
        translation: "vouloir dire"
    },
    {
        base: "meet",
        past_simple: "met",
        past_participle: "met",
        translation: "rencontrer"
    },
    {
        base: "melt",
        past_simple: "melted/molten",
        past_participle: "melted/molten",
        translation: "fondre"
    },
    {
        base: "mislead",
        past_simple: "misled",
        past_participle: "misled",
        translation: "induire en erreur"
    },
    {
        base: "mistake",
        past_simple: "mistook",
        past_participle: "mistaken",
        translation: "confondre/se tromper"
    },
    {
        base: "mow",
        past_simple: "mowed",
        past_participle: "mown/mowed",
        translation: "tondre"
    },
    {
        base: "overcome",
        past_simple: "overcame",
        past_participle: "overcome",
        translation: "surmonter"
    },
    {
        base: "overdo",
        past_simple: "overdid",
        past_participle: "overdone",
        translation: "en faire trop"
    },
    {
        base: "overtake",
        past_simple: "overtook",
        past_participle: "overtaken",
        translation: "dépasser"
    },
    {
        base: "overthrow",
        past_simple: "overthrew",
        past_participle: "overthrown",
        translation: "renverser"
    },
    {
        base: "pay",
        past_simple: "paid",
        past_participle: "paid",
        translation: "payer"
    },
    {
        base: "prove",
        past_simple: "proved",
        past_participle: "proven/proved",
        translation: "prouver"
    },
    {
        base: "put",
        past_simple: "put",
        past_participle: "put",
        translation: "mettre"
    },
    {
        base: "quit",
        past_simple: "quit",
        past_participle: "quit",
        translation: "quitter"
    },
    {
        base: "read",
        past_simple: "read",
        past_participle: "read",
        translation: "lire"
    },
    {
        base: "rid",
        past_simple: "rid",
        past_participle: "rid",
        translation: "se débarrasser de"
    },
    {
        base: "ride",
        past_simple: "rode",
        past_participle: "ridden",
        translation: "monter à cheval/à vélo"
    },
    {
        base: "ring",
        past_simple: "rang",
        past_participle: "rung",
        translation: "sonner"
    },
    {
        base: "rise",
        past_simple: "rose",
        past_participle: "risen",
        translation: "se lever"
    },
    {
        base: "run",
        past_simple: "ran",
        past_participle: "run",
        translation: "courir"
    },
    {
        base: "saw",
        past_simple: "sawed",
        past_participle: "sawn/sawed",
        translation: "scier"
    },
    {
        base: "say",
        past_simple: "said",
        past_participle: "said",
        translation: "dire"
    },
    {
        base: "see",
        past_simple: "saw",
        past_participle: "seen",
        translation: "voir"
    },
    {
        base: "seek",
        past_simple: "sought",
        past_participle: "sought",
        translation: "chercher"
    },
    {
        base: "sell",
        past_simple: "sold",
        past_participle: "sold",
        translation: "vendre"
    },
    {
        base: "send",
        past_simple: "sent",
        past_participle: "sent",
        translation: "envoyer"
    },
    {
        base: "set",
        past_simple: "set",
        past_participle: "set",
        translation: "fixer"
    },
    {
        base: "sew",
        past_simple: "sewed",
        past_participle: "sewn/sewed",
        translation: "coudre"
    },
    {
        base: "shake",
        past_simple: "shook",
        past_participle: "shaken",
        translation: "secouer"
    },
    {
        base: "shave",
        past_simple: "shaved",
        past_participle: "shaved/shaven",
        translation: "se raser"
    },
    {
        base: "shear",
        past_simple: "sheared",
        past_participle: "shorn/sheared",
        translation: "tailler (les cheveux, la laine)"
    },
    {
        base: "shed",
        past_simple: "shed",
        past_participle: "shed",
        translation: "verser (des larmes)"
    },
    {
        base: "shine",
        past_simple: "shone",
        past_participle: "shone",
        translation: "briller"
    },
    {
        base: "shoot",
        past_simple: "shot",
        past_participle: "shot",
        translation: "tirer"
    },
    {
        base: "show",
        past_simple: "showed",
        past_participle: "shown/showed",
        translation: "montrer"
    },
    {
        base: "shrink",
        past_simple: "shrank",
        past_participle: "shrunk",
        translation: "rétrécir"
    },
    {
        base: "shut",
        past_simple: "shut",
        past_participle: "shut",
        translation: "fermer"
    },
    {
        base: "sing",
        past_simple: "sang",
        past_participle: "sung",
        translation: "chanter"
    },
    {
        base: "sink",
        past_simple: "sank",
        past_participle: "sunk",
        translation: "couler"
    },
    {
        base: "sit",
        past_simple: "sat",
        past_participle: "sat",
        translation: "s'asseoir"
    },
    {
        base: "sleep",
        past_simple: "slept",
        past_participle: "slept",
        translation: "dormir"
    },
    {
        base: "slide",
        past_simple: "slid",
        past_participle: "slid",
        translation: "glisser"
    },
    {
        base: "smell",
        past_simple: "smelt",
        past_participle: "smelt",
        translation: "sentir (odeur)"
    },
    {
        base: "sow",
        past_simple: "sowed",
        past_participle: "sown/sowed",
        translation: "semer"
    },
    {
        base: "speak",
        past_simple: "spoke",
        past_participle: "spoken",
        translation: "parler"
    },
    {
        base: "speed",
        past_simple: "sped/speeded",
        past_participle: "sped/speeded",
        translation: "accélérer"
    },
    {
        base: "shave",
        past_simple: "shaved",
        past_participle: "shaved/shaven",
        translation: "se raser"
    },
    {
        base: "shear",
        past_simple: "shore",
        past_participle: "shorn",
        translation: "tondre"
    },
    {
        base: "shed",
        past_simple: "shed",
        past_participle: "shed",
        translation: "verser/perdre"
    },
    {
        base: "shine",
        past_simple: "shone",
        past_participle: "shone",
        translation: "briller"
    },
    {
        base: "shoot",
        past_simple: "shot",
        past_participle: "shot",
        translation: "tirer (avec une arme)"
    },
    {
        base: "show",
        past_simple: "showed",
        past_participle: "shown/showed",
        translation: "montrer"
    },
    {
        base: "shrink",
        past_simple: "shrank/shrunk",
        past_participle: "shrunk/shrunken",
        translation: "rétrécir"
    },
    {
        base: "shut",
        past_simple: "shut",
        past_participle: "shut",
        translation: "fermer"
    },
    {
        base: "sing",
        past_simple: "sang",
        past_participle: "sung",
        translation: "chanter"
    },
    {
        base: "sink",
        past_simple: "sank/sunk",
        past_participle: "sunk",
        translation: "couler"
    },
    {
        base: "sit",
        past_simple: "sat",
        past_participle: "sat",
        translation: "s'asseoir"
    },
    {
        base: "sleep",
        past_simple: "slept",
        past_participle: "slept",
        translation: "dormir"
    },
    {
        base: "slide",
        past_simple: "slid",
        past_participle: "slid",
        translation: "glisser"
    },
    {
        base: "sling",
        past_simple: "slung",
        past_participle: "slung",
        translation: "lancer"
    },
    {
        base: "slink",
        past_simple: "slunk",
        past_participle: "slunk",
        translation: "se faufiler"
    },
    {
        base: "slit",
        past_simple: "slit",
        past_participle: "slit",
        translation: "fendre"
    },
    {
        base: "smell",
        past_simple: "smelt/smelled",
        past_participle: "smelt/smelled",
        translation: "sentir (odeur)"
    },
    {
        base: "smite",
        past_simple: "smote",
        past_participle: "smitten",
        translation: "frapper"
    },
    {
        base: "sow",
        past_simple: "sowed",
        past_participle: "sown",
        translation: "semer"
    },
    {
        base: "speak",
        past_simple: "spoke",
        past_participle: "spoken",
        translation: "parler"
    },
    {
        base: "speed",
        past_simple: "sped",
        past_participle: "sped",
        translation: "aller vite"
    },
    {
        base: "spend",
        past_simple: "spent",
        past_participle: "spent",
        translation: "dépenser"
    },
    {
        base: "spill",
        past_simple: "spilt",
        past_participle: "spilt",
        translation: "renverser"
    },
    {
        base: "spin",
        past_simple: "spun",
        past_participle: "spun",
        translation: "tourner"
    },
    {
        base: "spit",
        past_simple: "spat",
        past_participle: "spat",
        translation: "cracher"
    },
    {
        base: "split",
        past_simple: "split",
        past_participle: "split",
        translation: "diviser"
    },
    {
        base: "spoil",
        past_simple: "spoiled/spoilt",
        past_participle: "spoiled/spoilt",
        translation: "gâcher, gâter"
    },
    {
        base: "spread",
        past_simple: "spread",
        past_participle: "spread",
        translation: "étendre, répandre"
    },
    {
        base: "spring",
        past_simple: "sprang",
        past_participle: "sprung",
        translation: "surgir, jaillir"
    },
    {
        base: "stand",
        past_simple: "stood",
        past_participle: "stood",
        translation: "être debout, supporter"
    },
    {
        base: "steal",
        past_simple: "stole",
        past_participle: "stolen",
        translation: "voler, dérober"
    },
    {
        base: "stick",
        past_simple: "stuck",
        past_participle: "stuck",
        translation: "coller, être coincé"
    },
    {
        base: "sting",
        past_simple: "stung",
        past_participle: "stung",
        translation: "piquer, être douloureux"
    },
    {
        base: "stink",
        past_simple: "stank/stunk",
        past_participle: "stunk",
        translation: "puer, empester"
    },
    {
        base: "stride",
        past_simple: "strode",
        past_participle: "stridden",
        translation: "marcher à grands pas"
    },
    {
        base: "strike",
        past_simple: "struck",
        past_participle: "struck/stricken",
        translation: "frapper, toucher"
    },
    {
        base: "swear",
        past_simple: "swore",
        past_participle: "sworn",
        translation: "jurer, promettre"
    },
    {
        base: "sweep",
        past_simple: "swept",
        past_participle: "swept",
        translation: "balayer, nettoyer"
    },
    {
        base: "swell",
        past_simple: "swelled",
        past_participle: "swollen/swelled",
        translation: "gonfler, enfler"
    },
    {
        base: "swim",
        past_simple: "swam",
        past_participle: "swum",
        translation: "nager"
    },
    {
        base: "swing",
        past_simple: "swung",
        past_participle: "swung",
        translation: "balancer"
    },
    {
        base: "take",
        past_simple: "took",
        past_participle: "taken",
        translation: "prendre"
    },
    {
        base: "teach",
        past_simple: "taught",
        past_participle: "taught",
        translation: "enseigner"
    },
    {
        base: "tear",
        past_simple: "tore",
        past_participle: "torn",
        translation: "déchirer"
    },
    {
        base: "tell",
        past_simple: "told",
        past_participle: "told",
        translation: "dire"
    },
    {
        base: "think",
        past_simple: "thought",
        past_participle: "thought",
        translation: "penser"
    },
    {
        base: "throw",
        past_simple: "threw",
        past_participle: "thrown",
        translation: "jeter"
    },
    {
        base: "understand",
        past_simple: "understood",
        past_participle: "understood",
        translation: "comprendre"
    },
    {
        base: "wake",
        past_simple: "woke",
        past_participle: "woken",
        translation: "réveiller"
    },
    {
        base: "wear",
        past_simple: "wore",
        past_participle: "worn",
        translation: "porter (vêtement)"
    },
    {
        base: "win",
        past_simple: "won",
        past_participle: "won",
        translation: "gagner"
    },
    {
        base: "write",
        past_simple: "wrote",
        past_participle: "written",
        translation: "écrire"
    }
]

export {verbesIrreguliers};