import * as sdk from "microsoft-cognitiveservices-speech-sdk";


export default function initAudio()
{
    const speechConfig = sdk.SpeechConfig.fromSubscription("3d4efdbb06c14e55917a88f3537e9bf8", "westeurope");
    const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
    speechConfig.speechRecognitionLanguage = "en-GB";
    const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
    return recognizer;
}