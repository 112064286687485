
import { readListErrors, saveErrorList } from '../../components/commun/localStorage';

export default class Erreur
{
    constructor(nom)
    {
        this.listeErreurs = readListErrors(nom);
        if (this.listeErreurs.info.length  === 0)
        {
            this.listeErreurs  = {id : 9999, titre : "liste d'erreurs", niveau: 1, info : [] }
        }
        this.nom = nom;
    }
    verifierPresenceErreur(erreur)
    {
        let resultat = this.listeErreurs.info.findIndex(element => element.a === erreur.a);
        return resultat;
    }

    supprimerErreur(erreur)
    {
        let resultat = this.verifierPresenceErreur(erreur);
        if (resultat !== -1)
        {
            this.listeErreurs.info.splice(resultat,1);
        }
    }

    ajouterErreur(erreur)
    { 
        if (this.verifierPresenceErreur(erreur) === -1)
        {  
            this.listeErreurs.info.push(erreur);
        }
    }

    sauvegardeErreur()
    {
        saveErrorList(this.nom, this.listeErreurs);
    }

}