import React, { useState} from 'react';
import { Modal,  Input  } from 'antd';
// import { addFirstName} from '../../components/commun/localStorage';


export default function Prenom({callbackPrenom}) {

   const [visible, setVisible] = useState(true);
   const [prenom, setPrenom] = useState("");
   const [erreur, setErreur] = useState("");
  
  let handleOk = () => {
 
    if (prenom === "" || prenom.length <3 || prenom.length > 15) 
    {
        setErreur("Le prénom ne doit pas être vide et doit contenir plus de 3 lettres.")
    }
    else
    {
        callbackPrenom(prenom);
        setVisible(false);
      //  addFirstName(prenom);

    }
 };

 let handleCancel = (e) => {
    let prenom = "Inconnu" + Math.floor(Math.random()*1000);
  callbackPrenom(prenom);
  setVisible(false);
  //addFirstName(prenom);

};

    
   
   return  <Modal cancelButtonProps={{ style: { display: 'none' } }} onCancel={handleCancel} title="Votre prénom" visible={visible} onOk={handleOk}>
<p>Nous avons besoin de votre prénom, vous pourrez suivre la progression des participants sur notre tableau.</p>
<Input placeholder="Votre prénom" onChange={(event) => setPrenom(event.target.value)} value={prenom}></Input>
   <p className="centre espaceHaut">{erreur}</p>
 </Modal> 


}