import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Question from './Question';
import Logique from './Logique';
import Reponses from './Reponses';
import { message } from 'antd';
import Resultat from './Resultat';






export default class JeuVerbeIrregulier extends Component {

    constructor(props) {
        super();
        this.tabVerbe = Logique.listeVerbesIrreguliers();
        this.noEnCours = 0;
        this.verbe = this.tabVerbe[this.noEnCours];
        let verbe = Logique.tirageAuSortTemps(this.verbe);
        
        this.typeReponse = verbe.tempsChoisi;
        
        this.state = {
            verbe: verbe.verbeTrou,
            tabReponses: [],
            note: 0,
            afficheResultat: false
        };
     

    }
    nouvelleQuestion = () => {

        this.noEnCours++;
        
        if  (this.noEnCours >= this.tabVerbe.length){
            this.setState({ afficheResultat: true });
        }
        else
        {
        this.verbe = this.tabVerbe[this.noEnCours];
        let verbe = Logique.tirageAuSortTemps(this.verbe);
        this.typeReponse = verbe.tempsChoisi;

        this.setState({ verbe: verbe.verbeTrou });
        }

    }

    verifierReponse = (reponse) => {
        let check = true;
        let note = this.state.note;
        let nouveauTabReponses = [...this.state.tabReponses];
        let solution = { ...this.verbe, typeReponse: this.typeReponse }
        let listeReponses = this.verbe[this.typeReponse].split('/');
        let resultat = false;
        for (let i = 0; i < listeReponses.length; i++) {
            if (reponse === listeReponses[i]) {
                resultat = true;
                break;
            }
        }

        if (resultat) {
            message.success('Bonne réponse', 1, this.nouvelleQuestion);
            check = true;
            note+=2;

        } else {
            message.error('Mauvaise réponse (' + this.verbe[this.typeReponse] + ')', 2, this.nouvelleQuestion);
            check = false;
        }

        

        solution.check = check;

        nouveauTabReponses.unshift(solution);
        this.setState({ tabReponses: nouveauTabReponses, note });

    }



    render() {
        return <div>
            <Helmet>
                <title>Exercice de conjugaison des verbes irréguliers en anglais</title>
                <meta name="description" content="Un exercice pour réviser les verbes irréguliers." />
            </Helmet>

            {this.state.afficheResultat ? <Resultat note={this.state.note}></Resultat>:
                        <div><h1>Les verbes irréguliers anglais</h1>
        
                <Question verbe={this.state.verbe} verifierReponse={this.verifierReponse}></Question>
                <h2>Tes résultats</h2>
                <div>Note : {this.state.note}/20</div>
                <Reponses tabReponses={this.state.tabReponses}></Reponses>
            </div>}
        </div>
    }
}