import React, { Component } from 'react';
import { transformSentence} from '../commun/logiqueTraduction';
import {Button, Input} from 'antd';
const { TextArea } = Input;




export default class QuestionFerme extends Component
{
    constructor(props)
    {
        super(props);
        this.state = 
        {
            finReponse : false,
            resultat : false,
            mots : ''
        }
        
       
    }
   
    saisie = (event) =>
    {
        this.setState({mots : event.target.value});
    }

    reponse = () =>
    {
            let resultat = true;
            let tabMots = this.state.mots.split('\n');  
            let s = new Set(tabMots.map(x => transformSentence(x)));
            for (const mot of this.props.test.reponse) {
               
                if (!s.has(transformSentence(mot)))
                {
                    resultat = false;
                    break;
                }
            }     
        this.setState({finReponse : true,
                resultat});
                this.props.resultatQuestion(this.props.noQuestion, resultat);
    }

    render()
    {
    return <div className="questionsMem margeHaut20"><span style={{verticalAlign : 'top'}}>{(this.props.noQuestion+1) + '. ' + this.props.test.question}</span>
    {!this.state.finReponse ? <span>{this.props.test.reponse.length === 1 ? 
    <Input autoComplete="off" autoCorrect="off" autoCapitalize="off" onPressEnter={this.reponse}
spellCheck="false" value={this.state.mots} onChange={this.saisie} style={{width: '200px'}}></Input> : 
<TextArea rows="3"  autoComplete="off" autoCorrect="off" autoCapitalize="off" 
spellCheck="false" value={this.state.mots} onChange={this.saisie} style={{ lineHeight: '20px', resize:'none', width: '200px'}}></TextArea>}
    <Button type="primary" onClick={this.reponse}>OK</Button></span>: 
    this.state.resultat ? <span className="couleurOK" ><b>Bonne réponse</b></span>: <span className="couleurKO"><b>Mauvaise réponse</b></span>}</div>
    }
}