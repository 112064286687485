import React, { Component } from 'react';

export default class Audio extends Component {

    constructor() {
        super();
        this.audio = React.createRef();
    }
    componentDidMount() {
       

            let promise = this.audio.current.play();
      
            if (promise !== undefined) {
                promise.catch(error => {
                 console.log("pas démarré");
                 
                });

           };    
        
    }

    render() {
        return <audio  style={{ width: '300px' }}
        autoPlay={true}
            controls
            ref={this.audio}
            src={this.props.nomFichier}>
        </audio>

    }
}