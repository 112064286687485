import React, { Component } from 'react';
import { verifierStatus } from '../../components/commun/Utilitaire';
import { withRouter } from 'react-router-dom';
import ButtonLink from '../commun/ButtonLink';
import Prenom from '../commun/Prenom';
import Confetti from 'react-confetti';
import { addFirstName, readFirstName } from '../../components/commun/localStorage';

import {Helmet } from 'react-helmet';
import Ad from '../../components/commun/AdSense';

class Resultat extends Component {

    constructor(props) {
        super();
        this.note = Math.floor(20 -(0.8333333333333334 * props.erreurs));
        this.state = {
            prenom: "",
            resultat: 0,
            prenomVisible: false
        }
    
        }

   

    componentDidMount = () => {
        if (this.props.erreurs === 0) {
            const prenom = readFirstName();
            if (prenom === null) {
                this.setState({
                    prenomVisible: true
                })
            }
            else {

                this.envoyerMessage(prenom);

            }
        }
    }

    callbackPrenom = (prenom) => {
        prenom = prenom + "$" + Math.floor(Math.random() * 100000);
        addFirstName(prenom);

        this.envoyerMessage(prenom);
        this.setState({
            prenomVisible: false
        })


    }



    async envoyerMessage(prenom) {
        let url = new URL(process.env.REACT_APP_URL_FINCODE);


        const reponse = await fetch(url + "?prenom=" + prenom, {
            method: "GET",
        })
        if (!verifierStatus(reponse.status)) {
            return;
        }
        if (reponse.ok) {
            const res = await reponse.json();

            this.setState({
                resultat: res.resultat,
                prenom: res.prenom
            })

        }
        else {
            alert("Désolé, il y a un problème.")
            window.location.href = "/"
        }

    }



    messageFelicitation = () => {
        if (this.note <= 10) {
            return "Don't give up, you're making progress even if it doesn't feel like it.";
        }
        else if (this.note > 10 && this.note <= 12) {
            return "Keep practicing, every effort you make will bring you closer to your goal.";
 
        }
        else if (this.note > 12 && this.note <= 14) {          
             return "You've got what it takes to succeed, keep pushing yourself to be your best.";
        }
        else if (this.note > 14 && this.note <= 16) {
            return "You got this! Keep pushing yourself and you'll see great results.";
        }
        else if (this.note > 16 && this.note <= 18) {
            return "You did it! You should be so proud of yourself for all your hard work.";
        }
       
        else if (this.note > 18 && this.note <= 20) {
            return "You're an inspiration to others, showing them that anything is possible with hard work and determination.";

 }
    }

    render() {
        return <div>
             <Helmet>
                <title>Résultat du jeu du code secret anglais</title>
                <meta name="description" content="Résultat du jeu du code secret anglais" />
            </Helmet>
            
            <h1>Tes résultats</h1>
            {this.state.prenomVisible && <Prenom callbackPrenom={this.callbackPrenom}></Prenom>}
            <div className='centre fontMoyenne'>
                {this.props.erreurs === 0 ? <div>
                    <Confetti></Confetti>
                    <div>Fantastique <b>{this.state.prenom} !</b> Tu as réussi à décoder les 12 codes !</div>
                    <div>Tu es un(e) champion(ne) !</div>
                    <div className="margeHaut30">{this.messageFelicitation()}</div>
                    <div className="margeHaut30">{this.state.resultat === 1 ? "C'est la première fois que tu résussi les 24 codes" : "Tu as déjà réussi " + this.state.resultat + " fois les 24 codes. Félicitations."}</div>

                </div> : <div className="margeHaut30">
                    <p>Tu as obtenu la note de {this.note}/20.</p>
                    <p>{this.messageFelicitation()}</p></div>}

                <div className="margeHaut30"><ButtonLink titre="Refaire un autre test" href={'/jeu-code'}></ButtonLink></div>
                <div className="margeHaut30"><ButtonLink titre="Retour à l'accueil" href={'/'}></ButtonLink></div>

          
            </div>
        </div>
    }
}

export default withRouter(Resultat);