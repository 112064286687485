import React, { Component } from 'react';
import { Input } from 'antd';
import { Tooltip } from 'antd';



export default class Saisie extends Component {

    constructor() {
        super();
        this.state = {
            affichage: 'initial',
            motTape: ''
        }

    }
    changer = (event) => {
      
        if (event.target.value.charAt(event.target.value.length-1) === " ") {
            this.verifierMot();
        }
        else {
            this.setState({ motTape: event.target.value });
        }



    }
    verifierMot = () => {
        let resultat = 'erreur';
        if (this.state.motTape === '') this.setState({ motTape: 'XXX' });

        if (this.state.motTape.trim() === this.props.mot) {
            resultat = 'trouve';
        }

        this.props.afficherCompteur(resultat);

        this.setState({ affichage: resultat });


    }
    render() {

        return this.state.affichage === 'initial' ? <Input autoComplete="off" autoCorrect="off" autoCapitalize="off"
            spellCheck="false" maxLength={22} className="inputStyle" value={this.state.motTape} onChange={this.changer} >
        </Input> : this.state.affichage === 'erreur' ? <Tooltip title={'votre réponse : ' + this.state.motTape}><span className="errorStylePhraseTrou">{this.props.mot}</span></Tooltip> : <span className="okStylePhraseTrou">{this.props.mot}</span>

    }
}