import React, { Component } from 'react';
import donneesConcours from '../../data/dataConcours';
import ButtonLink from '../commun/ButtonLink';
import confetti from '../../images/confetti.webp';
import applause from '../../images/applause.webp';
import { Steps } from 'antd';
import victory from '../../images/victory.jpg'
import { prochainObjectif, messageErreur } from './logiqueConcours';
import { readJour, saveJour, addGameContest, addAttemptGameContest, addGame, addFirstName, readFirstName, readInfoContest } from '../../components/commun/localStorage';
import Prenom from '../commun/Prenom';
import Ad from '../../components/commun/AdSense';
import env from '../../components/commun/config';
import {Helmet } from 'react-helmet';

import Confetti from 'react-confetti';
const { Step } = Steps;


export default class FinEtape extends Component {

    constructor(props) {
        super();
        this.donneesJeu = props.donneesJeu;
        this.nbFautes = props.nbFautes;
        this.nom = props.nom;
        this.temps = props.temps;
        this.perdu = props.perdu;
        this.score = props.score;
        this.concours = null;
        this.erreur = false;
        this.prenomVisible = false;
        this.tentative = 1;
        this.prochainJour = 0;

        this.indexConcours = donneesConcours.findIndex(x => x.id === this.donneesJeu.idConcours);
        this.concours = donneesConcours[this.indexConcours];
        this.jour = readJour();
        this.jourFin = donneesConcours.length;
        if (this.concours == null) {
            this.erreur = true;

        }
        else {
            this.victoire = this.verificationVictoire();
            this.jeuSuivant = this.rechercheProchainJeu();
            let infoContest = readInfoContest(this.concours.id);
           
             if (infoContest === -1 || this.jeuSuivant.indexEnCours !== infoContest.level) {
                 this.erreur = true;
             }
             else {
                if (this.victoire) {


                    if (this.jeuSuivant.id !== -1) {
                     addGameContest(this.concours.id, this.jeuSuivant.indexEnCours + 1)
                    }
                    else {   
                        this.tentative = infoContest.attempt;
                                    
                        if (this.indexConcours+ 1 <= donneesConcours.length)
                        {
                       
                            this.prochainJour = this.indexConcours+1;
                            //test s'il refait un ancien jour, +1 car jour commence à 1
                            if (this.jour ===  this.indexConcours+1) 
                            {
                                this.verifierPrenom();
                                saveJour(this.jour+1);
                            }
                        }
                     
                         addGame('defi-anglais', this.donneesJeu.idConcours);
                         addGameContest(this.concours.id, 0)  //reset si gagné
                        
                    }
                }
                else {
                   addAttemptGameContest(this.concours.id);
                }
          }
       }
       
        this.state = { erreur: this.erreur };
    }

    componentDidMount = () => {
  

    }
    verifierPrenom = () => {
       const prenom = readFirstName();
         if (prenom === null) {
            this.prenomVisible = true;
         }
         else {
             this.envoyerMessage(prenom);
        }
    }

    callbackPrenom = (prenom) => {
        prenom = prenom + "$"+  Math.floor(Math.random()*100000);
       addFirstName(prenom);
         this.envoyerMessage(prenom);

    }

    verificationVictoire() {

        if ((this.perdu !== undefined && !this.perdu) ||
            (this.nbFautes !== undefined && this.donneesJeu.qualifFaute !== undefined && this.nbFautes <= this.donneesJeu.qualifFaute) ||
            (this.temps !== undefined && this.donneesJeu.qualifTemps !== undefined && this.temps <= this.donneesJeu.qualifTemps) ||
            (this.score !== undefined && this.donneesJeu.qualifScore !== undefined && this.score >= this.donneesJeu.qualifScore)) {
            return true;
        }


        return false;

    }

    envoyerMessage = (prenom) =>
    {
        let url = new URL(process.env.REACT_APP_URL_AJOUTERTABLEAU);
        var data = new FormData();
        data.append('prenom', prenom);
        data.append('noDefi', this.concours.id/10)
        fetch(url, {
            method: "POST",
            body: data
        })
    }

    rechercheProchainJeu() {
        let jeuSuivant = { type: '', id: -1, titre: '', index: -1 }
        let index = this.concours.liste.findIndex(x => x.id === this.donneesJeu.id);
        if (index === -1) {
            this.erreur = true;
        }
        if (!this.victoire)
        {
            jeuSuivant = this.concours.liste[index]
        }
        else if (index < this.concours.liste.length - 1)
        {
            jeuSuivant = this.concours.liste[index + 1];
        }

        return { ...jeuSuivant, indexEnCours: index };
    }



    render() {
        return <div><Helmet>
        <title>Résultats des tests d'évaluation en anglais (grammaire, conjugaison, vocabulaire, culture...)</title>
        <meta name="description" content="Résultats des tests d'évaluation en anglais"/>
          </Helmet>
            {this.state.erreur ? <div className="centre"><ButtonLink titre='Désolé, il y a un problème' href={'/'}></ButtonLink><p>Il est préférable de réaliser les exercices dans l'ordre pour ne pas avoir de message d'erreur.</p><p>Il ne faut pas recharger la page pour refaire l'exercice.</p></div> : 
            <div>{this.victoire ?
                this.jeuSuivant.id !== -1 ? <div className="centre">
                            <div><img className="resp" src={confetti} alt="concours"></img></div>
                    <h1 className="animationSuite  couleurHonneur">Parfait, vous avez réussi le test.</h1>
                    <p><ButtonLink titre="Commencer le prochain test" href={'/' + this.jeuSuivant.type + '/' + this.jeuSuivant.id}></ButtonLink></p>
                    <p>Objectif prochain test : <b>{prochainObjectif(this.jeuSuivant.id)}</b></p>
                </div>

                    : <div className="centre"><Confetti width={window.innerWidth - 30} height={300} />
                      <div><img className="resp" src={applause} alt="applaudissement"></img></div>
                        <div className="animationSuite"><p className="grandeFont couleurHonneur">Bravo ! Vous avez validé la journée.</p>
                    <h1>{this.concours.titre}</h1></div>
                        <p>Vous avez eu besoin de <b>{this.tentative} {this.tentative <= 1 ? "tentative" : "tentatives"}</b>.</p><img className="img-responsive" src={victory} alt="victoire"></img>
                        {this.prochainJour > 0 && this.prochainJour < 30 && 
                        <div className="margeHaut"><ButtonLink titre="Commencer le prochain jour" href={'/' + env.nomDefi + '/' + donneesConcours[this.prochainJour].id}></ButtonLink></div>}
                    {this.indexConcours+1 === this.jourFin && <p className="moyenneFont">Fantastique ! Vous avez validé toutes les journées. Merci pour votre participation.</p>}
                        {this.prenomVisible && <Prenom callbackPrenom={this.callbackPrenom}></Prenom>
                        }</div>
                : <div className="centre"><p className="couleurResultat moyenneFont"><b>Malheureusement vous n'avez pas réussi le test.</b></p>
                <p>{messageErreur(this.donneesJeu.id)}</p>
                    <p>Vous pouvez recommencer.</p>
                    <div><ButtonLink titre='Refaire le test' href={'/' + this.jeuSuivant.type + '/' + this.jeuSuivant.id}></ButtonLink></div></div>}
                <div className="margeStep">
                    <Steps size="small" current={this.victoire ? this.jeuSuivant.indexEnCours + 1 : this.jeuSuivant.indexEnCours}>
                        {this.concours.liste.map((jeu, i) => <Step key={i} title={jeu.titre}></Step>)}
                        <Step title="Fin"></Step>
                    </Steps>
                    <div className="espaceHaut30 centre"><ButtonLink titre="Revenir à l'accueil" href={'/'}></ButtonLink></div>
                    <p className="centre espaceHaut30">Votre progression est automatiquement sauvegardée.</p>
                </div>
             
            </div>}
        </div>
    }
} 