import React, { Component } from 'react';
import Audio from './Audio';
import Saisie from '../commun/Saisie';
import Logique from './Logique';
import { message } from 'antd';
import Resultat from './Resultat';
import VirtualKeyboard from '../../components/commun/Keyboard';
import { Helmet } from 'react-helmet';

export default class JeuCode extends Component {

    constructor(props) {
        super();
        this.logique = new Logique();
        this.logique.construireCodes(0);

        this.codes = this.logique.codes.toLocaleUpperCase();
        this.erreurs = 0;
        this.stop = false;
        this.state = {
            fichiers: this.logique.fichiers,
            mot: '',
            afficheAudio: true,
            noMot: 0,
            afficheResultat: false
        }
        this.fichierEnCours = 0;

    }

    change = (mot) => {
        this.setState({ mot: mot });
    }

    nouveauCode = () => {
        let noMot = this.state.noMot + 1;

        if (noMot === 12) {
            this.setState({ afficheResultat: true });
        }
        else {
            this.logique.construireCodes(Math.trunc(noMot / 4));
            this.codes = this.logique.codes.toLocaleUpperCase();

            this.setState({ fichiers: this.logique.fichiers, mot: '', afficheAudio: true, noMot });
            this.stop = false;
        }

    }
    verifierReponse = (mot) => {  
          if (this.stop) return;
        if (mot.length === this.codes.length) {
        
            this.stop = true;
            console.log(this.codes)
            if (mot === this.codes) {
                message.success('Bon code', 1.5, this.nouveauCode);
            }
            else {
                message.error('Mauvais code (' + this.codes + ')', 1.5, this.nouveauCode);
                this.erreurs++;
            }

            this.setState({ afficheAudio: false });
            return true;
        }
        return false;
    }


    render() {
        return <div>
            <Helmet>
                <title>Comprendre les nombres et l'alphabet anglais</title>
                <meta name="description" content="Trouve les nombres et les lettres épelés. Un jeu idéal pour mieux comprendre l'alphabet, les nombres et les signes de ponctuation en anglais." />
            </Helmet>
 
            {this.state.afficheResultat ? <Resultat erreurs={this.erreurs}></Resultat> : <React.Fragment> 
                           <div className="titre">Le code des nombres et de l'alphabet</div>
            <p>Si l'audio ne démarre pas automatiquement, clique sur le symbole avec les doubles barres (pause) pour commencer le test. Ensuite il suffit d'écrire ce que l'on entend.</p>

                
                  <div>Code {this.state.noMot + 1} / 12</div>
                <div className="audioVocalCode">{this.state.afficheAudio && <Audio fichiers={this.state.fichiers} />}</div>
                {/* <div className="saisieVocal"><Saisie change={this.change} mot={this.state.mot} verifierReponse={this.verifierReponse}></Saisie></div> */}
                <VirtualKeyboard verifierReponse={this.verifierReponse}></VirtualKeyboard>
            </React.Fragment>}  </div>

    }
}

export { JeuCode };