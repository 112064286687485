import React, { Component } from 'react';
import { verifierStatus } from '../../components/commun/Utilitaire';
import { withRouter } from 'react-router-dom';
import ButtonLink from '../commun/ButtonLink';
import Prenom from '../commun/Prenom';
import Confetti from 'react-confetti';
import { addFirstName, readFirstName } from '../../components/commun/localStorage';

import {Helmet } from 'react-helmet';
import Ad from '../../components/commun/AdSense';

class Resultat extends Component {

    constructor(props) {
        super();
        this.state = {
            prenom: "",
            resultat: 0,
            prenomVisible: false
        }

        }

   

    componentDidMount = () => {
        if (this.props.note === 20) {
            const prenom = readFirstName();
            if (prenom === null) {
                this.setState({
                    prenomVisible: true
                })
            }
            else {

                this.envoyerMessage(prenom);

            }
        }
    }

    callbackPrenom = (prenom) => {
        prenom = prenom + "$" + Math.floor(Math.random() * 100000);
        addFirstName(prenom);

        this.envoyerMessage(prenom);
        this.setState({
            prenomVisible: false
        })


    }



    async envoyerMessage(prenom) {
        let url = new URL(process.env.REACT_APP_URL_FINVERBESIRREGULIERS);


        const reponse = await fetch(url + "?prenom=" + prenom, {
            method: "GET",
        })
        if (!verifierStatus(reponse.status)) {
            return;
        }
        if (reponse.ok) {
            const res = await reponse.json();

            this.setState({
                resultat: res.resultat,
                prenom: res.prenom
            })

        }
        else {
            alert("Désolé, il y a un problème.")
            window.location.href = "/"
        }

    }



    messageFelicitation = (note) => {
        if (note <= 10) {
            return "There is significant room for improvement here. Consider seeking additional support and resources to better understand the material and improve your performance.";
        }
        else if (note > 10 && note <= 12) {
            return "While there is certainly potential here, more work is needed to fully grasp the concepts. Consider seeking additional support and resources to improve your understanding.";
        }
        else if (note > 12 && note <= 14) {
            return "A solid effort, but there is room for improvement in certain areas. Keep working hard and seeking feedback to reach your full potential.";
        }
        else if (note > 14 && note <= 16) {
            return "Good effort, but there is room for improvement in certain areas. Keep working hard and seeking feedback to reach your full potential.";
        }
        else if (note > 16 && note <= 18) {
            return "Excellent work! Your attention to detail and understanding of the material is impressive. Keep up the great work.";
        }
        else if (note > 18 && note <= 20) {
            return "Wow, outstanding work! You clearly have a deep understanding of the material and have put in a lot of effort. Keep up the excellent work.";
        }


    }

    render() {
        return <div>
             <Helmet>
                <title>Résultat des exercices de conjugaison en anglais</title>
                <meta name="description" content="Les résultats des exercices de conjugaison des verbes irréguliers en anglais." />
            </Helmet>
            
            <h1>Tes résultats</h1>
            {this.state.prenomVisible && <Prenom callbackPrenom={this.callbackPrenom}></Prenom>}
            <div className='centre fontMoyenne'>
                {this.props.note === 20 ? <div>
                    <Confetti></Confetti>
                    <div>Fantastique <b>{this.state.prenom} !</b> Tu as réussi, tu as obtenu 20/20 !</div>
                    <div>Tu es un(e) champion(ne) !</div>
                    <div className="margeHaut30">{this.messageFelicitation(this.props.note)}</div>
                    <div className="margeHaut30">{this.state.resultat === 1 ? "C'est ton premier 20/20 sur les verbes irréguliers." : "Tu as déjà réussi " + this.state.resultat + " fois à obtenir 20/20. Félicitations."}</div>

                </div> : <div className="margeHaut30">
                    <p>Tu as obtenu la note de {this.props.note}/20.</p>
                    <p>{this.messageFelicitation(this.props.note)}</p></div>}

                <div className="margeHaut30"><ButtonLink titre="Refaire un autre test" href={'/verbes-irreguliers'}></ButtonLink></div>
                <div className="margeHaut30"><ButtonLink titre="Retour à l'accueil" href={'/'}></ButtonLink></div>

            
            </div>
        </div>
    }
}

export default withRouter(Resultat);