function transformSentence(sentence) {
    sentence = sentence.trim();
    let lastElement = sentence.charAt(sentence.length - 1);
    let firstElement = sentence.charAt(0);
    let secondElement = sentence.charAt(1);
    let thirdElement = sentence.charAt(2);

    let regPunctuation = /[,;:.!?\\-]/;

    if (lastElement.match(regPunctuation)) {
        sentence = sentence.slice(0, sentence.length - 1).trim();
    }

    if (!((firstElement === 'I' && secondElement === ' ') || (firstElement === 'I' && secondElement === '\''))) {
   
        sentence = firstElement.toLowerCase() + sentence.slice(1);
    }

    if (firstElement === 't' && secondElement=== 'o' && thirdElement === ' ')
    {
        sentence = sentence.slice(3);
    }
    return sentence;
}

function lettresDebut(phrase, nombre) {
    let tabPhrase = phrase.split('');
    tabPhrase.push('@');
    let regPunctuation = /[,;:.!@?\\-\s]/;
    
    let motEnCours = '';
    let phraseEnCours = '';
    let tabPremiereLettre = [];
    for (const lettre of tabPhrase) {
        if (lettre.match(regPunctuation)) {

            if (motEnCours.length > 2) 
            {
                motEnCours = tabPremiereLettre.join('') + motEnCours.slice(tabPremiereLettre.length);
            }
            if (motEnCours.length > 0)
            {
               phraseEnCours += motEnCours;
                tabPremiereLettre = [];
                motEnCours = '';
            }
            if (lettre !== '@') phraseEnCours += lettre;
        }
        else {
            if (motEnCours.length < nombre) tabPremiereLettre[motEnCours.length] = lettre;
            motEnCours += '-';
        }

    } 
      
    return phraseEnCours;
}

function motAideEnCours(phrase, noMot)
{
    if (noMot === -1) return phrase;
    let tabPhrase = phrase.split(' ');
    if (noMot > tabPhrase.length-1) return phrase;
    tabPhrase[noMot] = '<span class="motEnCoursVocal">' + tabPhrase[noMot] + '</span>';

    return tabPhrase.join(' ');
}


export { transformSentence, lettresDebut, motAideEnCours };