import React, { Component } from 'react';
import cancel from '../../images/cancel.png';
import check from '../../images/check.png';




export default class Reponses extends Component {

   
  
    render() {
        return <div><table className="viReponses">
<thead>
                <tr>
                    <th>Infinitif sans to</th>
                    <th>Prétérit</th>
                    <th>Participe passé</th>
                    <th>Traduction</th>
                    <th>Résultat</th>
                </tr></thead>
            
                <tbody>
                    {this.props.tabReponses.map((reponse) => <tr key={reponse.base}>
                    <td>{reponse.typeReponse !== "base" ? reponse.base : <b>{reponse.base}</b>}</td>
                    <td>{reponse.typeReponse !== "past_simple" ? reponse.past_simple : <b>{reponse.past_simple}</b>}</td>
                    <td>{reponse.typeReponse !== "past_participle" ? reponse.past_participle: <b>{reponse.past_participle}</b>}</td>
                    <td>{reponse.translation}</td>
                    <td>{reponse.check ? <img src={check} alt="correct"></img>: <img src={cancel} alt="faute"></img>}</td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    }
}