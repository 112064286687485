import React, { Component } from 'react';
import donneesConcours from '../../data/dataConcours';
import ButtonLink from '../commun/ButtonLink';
import { prochainObjectif } from './logiqueConcours';
import { Steps } from 'antd';
import { readGameContest, addGameContest } from '../../components/commun/localStorage';

import {Helmet } from 'react-helmet';

const { Step } = Steps;




export default class DebutEtape extends Component {

    constructor(props) {
        super();
        const no = parseInt(props.match.params.no);
        this.concours = donneesConcours.find(x => x.id === no);
        if (this.concours == null) this.erreur = true;
        else {
             this.noEtape = parseInt(readGameContest(this.concours.id));
             if (this.noEtape === -1) {
                 addGameContest(this.concours.id, 0);
                 this.noEtape = 0;
            }
            this.jeu = this.concours.liste[this.noEtape];
      
        }
    }


    render() {
        return   <React.Fragment><Helmet>
        <title>Tests d'anglais (grammaire, conjugaison, vocabulaire, culture...)</title>
        <meta name="description" content="Début des tests de niveau d'anglais"/>
          </Helmet>
          {this.erreur ? <div className="centre"><ButtonLink titre='Désolé, il y a un problème' href={'/'}></ButtonLink></div> : 
        <div><div className="centre"><h1 className="couleurTitre">{this.concours.nom}</h1>
            <p>Il vous reste {this.concours.liste.length - this.noEtape} {this.concours.liste.length - this.noEtape === 1 ? 'étape' : 'étapes'} pour valider la journée.</p>
            <h1>{this.concours.titre}</h1>
            <p className="margeHaut10"><ButtonLink titre="Commencer le test" href={'/' + this.jeu.type + '/' + this.jeu.id}></ButtonLink></p>
            <p>Objectif prochain test : <b>{prochainObjectif(this.jeu.id)}</b></p>
        </div>
            <div className="margeStep">
                <Steps current={this.noEtape} >
                    {this.concours.liste.map((jeu, i) => <Step key={i} title={jeu.titre}></Step>)}
                    <Step title="Fin"></Step>
                </Steps>

            </div>
            <p className="centre">Votre progression est automatiquement sauvegardée.</p>
        </div>}</React.Fragment>

    }
}

