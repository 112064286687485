
import SousMenu from './SousMenu';
import React, { Component } from 'react';
import { Row, Col} from 'antd';
import env from '../commun/config.js';
import border from '../../images/border.png';

import { Helmet } from 'react-helmet';
import Ad from '../commun/AdSense';
import Classement from '../commun/Classement.js';
import { Link } from 'react-router-dom';
import Liens from './Liens';

export default class Alphabet extends Component {

  constructor()
  {
    super();

  }

    render() {
        return <div>
             <Helmet>
          <title>Apprendre ou réviser l'alphabet et les nombres anglais</title>
          <meta name="description" content="Un petit jeu simple et amusant pour apprendre ou réviser les nombres et l'alphabet anglais. Parviendrez-vous au bout du défi ?"/>
                 </Helmet>
           
      <h1 className="titre centre">Apprends les lettres et les nombres anglais en t'amusant ! 🎉</h1>
   
    <div className="centre"><img src={border} alt="bordure"></img></div> 




      <Row  gutter={{md:16}} className="margeHaut20">

<Col md={24}>

<p>Découvrez "le code secret", le jeu éducatif parfait pour les enfants, les débutants et tous les autres qui souhaitent maîtriser ou réviser les bases de l'anglais tout en s'amusant.</p>
<p>Il est souvent difficile pour des francophones de reconnaitre les lettres et les nombres en anglais. Ce jeu est parfait pour résoudre ce problème.</p>
<p>Un code de 3 symboles est épelé à voix haute, l'audio est nécessaire pour jouer au jeu. Vous devez correctement le retranscrire. Le jeu comporte 12 codes aléatoires à découvrir. Un jeu simple et amusant pour bien reconnaitre les chiffres, les nombres et les signes de ponctuation en anglais. </p>
  <ul>
    <li>code 1 à 4 : le code est composé de 3 lettres.</li>
    <li>code 4 à 8 : le code est composé de 2 lettres et d'un nombre.</li>
    <li>code 8 à 12 : le code est composé d'une lettre, d'un nombre et d'un symbole présent sur le clavier (?!.,).</li>
  </ul>
<p>Vous pouvez trouver des informations sur la ponctuation anglaise avec notre page de <a href="https://anglais.evalquiz.com/blog/ponctuation">cours anglais sur la ponctuation.</a></p>





<div className="centre jeuVi margeHaut30"><Link to="/jeu-code">Le jeu des lettres et des nombres</Link></div>
</Col>
</Row>



<h2 className='margeHaut30'>Le classement des champions des lettres et nombres</h2>
<Classement jeu="code"></Classement>
 <div className="centre margeHaut"><img src={border} alt="bordure"></img></div>

<div className="centre"><img src={border} alt="bordure"></img></div>
            <Liens></Liens>
        </div>
    }
}