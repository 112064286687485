import React, { Component } from 'react';
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import initAudio from '../commun/initAudio';
import { Button } from 'antd';
import Audio from '../commun/Audio';
import Resultat from '../../components/commun/Resultat';
import Statistique from '../../components/commun/Statistique';
import { Redirect } from 'react-router-dom';
import { addGame } from '../../components/commun/localStorage';
import DataAccess from '../../data/DataAccess';

import { Helmet } from 'react-helmet';
import audio from '../../images/boutonAudio.JPG';
import env from '../../components/commun/config';
import FinEtape from '../concours/FinEtape';
import micro from '../../images/micro.jpg';


export default class JeuTraduction extends Component {

    constructor(props) {
        super();
        this.id = parseInt(props.match.params.id);
        this.data= new DataAccess(env.nomOral, this.id);
        this.dataJeu = this.data.obtenirDonneesJeu();
        this.stop = false;
        if (this.dataJeu === undefined) {
            this.stop = true;
        }
        else {
            this.messageBouton = ["Démarrer l'enregistrement", "Suivant"];
            this.noQuestion = 0;
            this.nbQuestion = this.dataJeu.info.length;
            this.nom = env.nomOral;
            this.audioEncours = false;
            this.state = {
                phraseJoueur: '',
                texte: this.dataJeu.info[this.noQuestion].texte,
                nomFichier: '/audio/' + this.dataJeu.info[this.noQuestion].audio,
                fond: 'fondNormal',
                messageBouton: this.messageBouton[0],
                tabMessage: [],
                afficheResultat: false,
                aide: false,
                audioDemmare: true
            };
            this.stat = new Statistique(this.nbQuestion);
            addGame(this.nom, this.id);
         
            this.recognizer = initAudio();
        }
    }



    testResultat = (phrase) => {

        let fond = 'fondCorrect';
        let aide = false;
        if (phrase !== this.dataJeu.info[this.noQuestion].texte) {
            fond = 'fondErreur';
            aide = true;
            this.stat.ajouterFaute();
        }

        this.setState({ messageBouton: this.messageBouton[1], aide, fond, phraseJoueur: phrase });
    }
    suivant = () => {
        let nouveauMessage = [...this.state.tabMessage];

        nouveauMessage.push({
            fond: this.state.fond,
            texte: this.dataJeu.info[this.noQuestion].texte,
        });

        this.noQuestion++;
        if (this.noQuestion === this.nbQuestion) {
            this.setState({
                afficheResultat: true,
                audioDemmare: true
            });
        }
        else {
            this.setState({
                texte: this.dataJeu.info[this.noQuestion].texte,
                nomFichier: '/audio/' + this.dataJeu.info[this.noQuestion].audio,
                phraseJoueur: '',
                fond: 'fondNormal',
                tabMessage: nouveauMessage,
                messageBouton: this.messageBouton[0],
                aide: false
            });
        }
    }

    voirTexte = () => {
        this.setState({
            aide: true
        });
    }
    audioDepart = () => {
        this.setState({ audioDemmare: false });
    }

    recommencer = () =>
    {
        this.setState({
            phraseJoueur: '',
            fond: 'fondNormal',
            messageBouton: this.messageBouton[0],
            aide: false
        });
    }

    start = () => {
        if (!this.audioEncours) {
            if (this.state.messageBouton === this.messageBouton[0]) {
                this.audioEncours = true;
                this.recognizer.recognizeOnceAsync(result => {
                    switch (result.reason) {
                        case sdk.ResultReason.RecognizedSpeech:
                            this.testResultat(result.text);
                            break;
                        case sdk.ResultReason.NoMatch:
                            alert("La voix n'a pas été reconnue");
                            this.testResultat('');
                            break;
                        case sdk.ResultReason.Canceled:
                            const cancellation = sdk.CancellationDetails.fromResult(result);
                            alert("désolé, il y a une erreur : " + cancellation.reason);
                            this.testResultat('');
                            break;
                        default:
                            alert("désolé, il y a une erreur : ");
                            this.testResultat('');
                            break;
                    }
                    this.audioEncours = false;
                });
            }
            else {
                this.suivant();
            }
        }
    }

    render() {
        if (this.stop) {
            return (<Redirect to='/'></Redirect>);
        }
        return <div>
            <Helmet>
                <title>S'entraîner à parler Anglais</title>
                <meta name="description" content="Pouvez-vous vous faire comprendre en Anglais ? Votre accent est-il correct ? N'hésitez pas à réaliser ce test et exprimez-vous à l'oral en Anglais." />
            </Helmet>


            {!this.state.afficheResultat ? <div><h1>Parler Anglais</h1>{!this.state.audioDemmare && <div><p>Cliquez sur le bouton Play <img src={audio} alt="bouton audio"></img> du lecteur audio pour écouter la phrase en anglais.</p>
            </div> }

        <div>
                    <p>Vous devez répéter les phrases anglaises que vous entendez.</p>
                    <p>Il est nécessaire de posséder un micro pour réaliser cet exercice et d'autoriser l'accès au micro.
                    L'enregistrement commence après un clic sur le bouton "Démarrez l'enregistrement" quand vous voyez le point rouge dans les onglets en haut de l'écran <img src={micro} alt="point rouge"></img> (ne commencez pas à parler avant l'enregistrement).
             La fin de l'enregistrement et la ponctuation sont détectés automatiquement. Prononcez n'importe quel mot si vous souhaitez stopper l'enregistrement.</p>
                </div>
            
                {this.state.tabMessage.map((message, i) =>
                    <div key={i}><div className={"questionOral texteOral " + message.fond}>{message.texte}</div></div>)}
                <div className={"questionOral " + this.state.fond}>
                    <Audio audioDepart={this.audioDepart} depart={true} nomFichier={this.state.nomFichier}></Audio>
                    <div><Button type="primary" onClick={this.start}>{this.state.messageBouton}</Button>
                    {this.state.fond === 'fondErreur' && <Button  className="margeGauche" type="primary" onClick={this.recommencer}>Recommencer</Button> } </div>
                    <div className="texteOral moyenneFont violet">{this.state.phraseJoueur}</div>
                    {this.state.fond === 'fondNormal' && <div className="aideOral">{this.state.aide ? this.state.texte : <Button onClick={this.voirTexte}>Voir le texte</Button>}</div>}
                </div>

            </div> :(this.data.concours ? <FinEtape nom={this.nom} donneesJeu={this.data.jeu} nbFautes={this.stat.resultat.nbFautes}></FinEtape>:
                <Resultat nom={this.nom} id={this.id} statistique={this.stat} donnees={this.data}></Resultat>)}
        </div>

    }
}