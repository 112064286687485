import React, { Component } from 'react';
import { Statistic, Button } from 'antd';
import { replayGame } from './localStorage';
import { withRouter, Link, Redirect} from 'react-router-dom';
import { message } from 'antd';
import SousMenu from '../principal/SousMenu';
import Ad from './AdSense';

class Resultat extends Component {

    constructor(props)
    {
        super();
        this.dejaFait = false;
        this.state = {
            redirection: false,
            annuler: false
        }
        
        this.prochain = props.donnees.obtenirProchainJeu(props.id, props.nom);
        this.cheminProchain = null;
    }
    revoir = () => {
        if (!this.dejaFait)
        {
            replayGame(this.props.nom, this.props.id);
            message.success("C'est noté", 1.5);
            this.dejaFait= true;
        }
    }

    refaire = () => {
        this.props.history.push('/' + this.props.nom +'/'+ this.props.id)
    }

    messageFelicitation = (note) =>
    {
        if (note > 19)
        {
            return "Perfect !"
        }
        else if (note > 17)
        {
            return "Brilliant";
        }
        else if (note > 15)
        {
            return "Awesome";
        }

        return "OK";
    }


 

    annuler = () => {
        this.setState({
            annuler: true
        })
    }

    renderProchainJeu() {
        if (this.prochain == null) return
        this.cheminProchain = '/' + this.props.nom +'/'+ this.prochain.id;
        return  !this.state.annuler && <div><div className="centre margeHaut"><b>Prochain test : </b> <Link to={this.cheminProchain}>{this.prochain.titre}</Link></div><div>
        </div></div>
    }


    render() {
        if (this.state.redirection) {
            return <Redirect to={this.cheminProchain}></Redirect>
        }
        else {
        const note = this.props.statistique.calculerNote();
        return <div className="centre"><h1 className="couleurResultat">Résultats</h1>
            <div>Nombre de fautes : {this.props.statistique.resultat.nbFautes}</div>
            <div className="margHaut">    <Statistic title="Note " value={note} suffix={'/ 20'} /></div>
            {note > 15 && <p className="moyenneFont violet">{this.messageFelicitation(note)}</p>}
            {this.props.statistique.resultat.listeFautes.length !== 0 && <div>
                <h3>Ce que vous devez revoir</h3>
                {this.props.statistique.resultat.listeFautes.map((mot, i) => <div key={i}>{mot}</div>)}
            </div>}
            
            {this.props.id !== 9999 && <React.Fragment> <p className="margeHaut">Si vous le désirez vous pouvez ajouter ce test à votre liste de tests à revoir (disponible sur la page d'accueil).</p><Button onClick={this.refaire} type="primary">le refaire tout de suite</Button>
           <Button className="margeGauche" onClick={this.revoir} type="primary">le refaire plus tard</Button></React.Fragment>}
           {this.renderProchainJeu()}
            <SousMenu className="tailleResultat margeHaut" nom={this.props.nom}  titre="Exercices du même type" supprimer={this.props.id}></SousMenu>
            <p className="fontMoyenne centre"><Link to='/'>Retour à la page d'accueil</Link></p>
  
        </div>
        }
    }

}

export default withRouter(Resultat);