import React, { Component } from 'react';
import Lettre from './Lettre'

export default class MotDefinition extends Component {

clickMot = (event) =>
{
    let id =  parseInt(event.currentTarget.id);
    this.props.afficheSaisie(id);
}

render() {
    return (<div id={this.props.numero} onClick={this.clickMot} className="definitionLettres"><span className="definitionSeul">{this.props.definition}</span><Lettre  mot={this.props.mot}></Lettre></div>)
}
}