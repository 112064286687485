import { verbesIrreguliers } from "../../data/dataVerbesIrreguliers"


export default class logique
{
    //Génère une liste de 20 verbes irréguliers pris parmi la liste des verbes irréguliers
    static  listeVerbesIrreguliers()
    {
        let listeVerbesIrreguliers = []; 
        let i = 0;
        while(i < 10)
        {
            let verbe = verbesIrreguliers[Math.floor(Math.random() * verbesIrreguliers.length)];
            if(!listeVerbesIrreguliers.includes(verbe))
            {
                listeVerbesIrreguliers.push(verbe);
                i++;
            }
        }
        return listeVerbesIrreguliers;
    }  
    static tirageAuSortTemps(verbe)
    {   let verbeTrou = {...verbe};
        let temps = ["base", "past_simple", "past_participle"];
        let tempsChoisi = temps[Math.floor(Math.random() * temps.length)];
        verbeTrou[tempsChoisi] = "AREMPLIR";
  
        
        return {verbeTrou, tempsChoisi};
    }
}