import { Modal, Button } from 'antd';
import React, { Component } from 'react';

export default class Regle extends Component {
  state = { visible: false };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <>
        <Button type="default" onClick={this.showModal}>
          Règles du jeu
        </Button>
        <Modal
          title="Règles du jeu"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
        <p>Le but du jeu est simple il suffit de retrouver un mot anglais à partir d’une définition en anglais. </p>
<p>Le fait de trouver un mot affiche les lettres du mot dans les définitions situées juste en-dessous. </p>
<p>Si toutes les lettres ne sont pas affichées on remonte à la première définition en haut pour afficher les lettres. </p>
<p>Le logiciel ne prend pas en compte les majuscules. Nous ne donnons pas la solution complète de chaque jeu.</p>
<p>Vous devez faire preuve d’un peu de stratégie dans l'ordre des réponses à donner.  </p>
        </Modal>
      </>
    );
  }
}