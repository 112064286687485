import React, { Component } from 'react';
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import initAudio from '../commun/initAudio';
import { transformSentence } from '../commun/logiqueTraduction';
import { Helmet } from 'react-helmet';
import DataAccess from '../../data/DataAccess';
import { Button, message } from 'antd';
import { Compteur } from '../commun/Aide';
import Resultat from '../../components/commun/Resultat';
import Statistique from '../../components/commun/Statistique';
import { Redirect } from 'react-router-dom';
import { addGame } from '../../components/commun/localStorage';

import env from '../../components/commun/config';
import ListeMots from './ListeMots';
import FinEtape from '../concours/FinEtape';





export default class JeuMotParle extends Component {

    constructor(props) {
        super();
        this.id = parseInt(props.match.params.id);
        this.stop = false;
        this.data = new DataAccess(env.nomMotParle, this.id);
        this.dataJeu = this.data.obtenirDonneesJeuMelange();
        if (this.dataJeu == null) {
            this.stop = true;
        }
        else {
            this.noQuestion = 0;
            this.nbQuestion = this.dataJeu.info.length;

            this.state = {   
                motF: this.dataJeu.info[this.noQuestion].texteF,
                reponseEnCours: false,
                afficheResultat: false,
                phase: 1,
                motParle : '',
                aideErreur: false,
                animationMot: 'animationMotIn'
            }
            this.recognizer = initAudio();
            this.reponseEnCours = false;
            this.temps = this.dataJeu.temps;
            this.stat = new Statistique(this.nbQuestion);
            this.nom = env.nomMotParle;
            addGame(this.nom, this.id);
      
        }
    }

    start = () => {
            this.recognizer.recognizeOnceAsync(result => {
                switch (result.reason) {
                    case sdk.ResultReason.RecognizedSpeech:
                        this.testResultat(result.text);
                        return;
                    case sdk.ResultReason.NoMatch:
                        message.error("La voix n'a pas été reconnue", 1);
                        break;
                    case sdk.ResultReason.Canceled:
                        const cancellation = sdk.CancellationDetails.fromResult(result);
                        message.error("désolé, il y a un problème : " + cancellation.reason, 1);
                        break;
                    default:
                        message.error("désolé, il y a un problème technique",1);
                         break;
                }
                this.setState({aideErreur: true});
                //this.nouveauMot();
            });
    }

    testResultat = (motParle) =>
    {
        const motA = this.dataJeu.info[this.noQuestion].texteA;
        this.setState({motParle : transformSentence(motParle),animationMot: ''});
        if (transformSentence(motA) === transformSentence(motParle))
        {
            message.success("Well done!", 1, this.nouveauMot);
        }
        else
        {
            message.error("Sorry, that is incorrect.", 1);
            this.setState({aideErreur: true});
        }
    }
   

    nouveauMot = () => {
        this.noQuestion++;
        if (this.noQuestion === this.nbQuestion) {
            this.setState({
             afficheResultat: true
            })
        }
        else {
            this.setState({
                motF: this.dataJeu.info[this.noQuestion].texteF,
                motParle: '',
                reponseEnCours: false,
                animationMot: 'animationMotIn',
                aideErreur : false
            });
            this.start();
        }
    }


    finMot = () =>
    {
        this.start();
        this.setState({phase : 2});
    }

    finAide = () =>
    {
        this.start();
        this.setState({aideErreur : false,
           motParle: ''});
    }

    passer = () =>
    {
        this.stat.ajouterFaute();
        this.nouveauMot();
    }


    afficheAide = () =>
    {
        const aide = [this.dataJeu.info[this.noQuestion]];
            return <div><ListeMots donnees={aide} finMot={this.finAide} texte="Prononcer le mot à nouveau"></ListeMots><div className="centre margeHaut20"><Button onClick={this.passer}>Passer au suivant</Button><span>(+1 faute)</span></div></div>
    }

    render() {
        if (this.stop) {
            return (<Redirect to='/'></Redirect>);
        }
        return <React.Fragment><Helmet>
        <title>Test d'anglais : s'exprimer en anglais</title>
        <meta name="description" content="Prononcer des mots en anglais et améliorer son vocabulaire grâce à la reconnaissance vocale." />
    </Helmet>{this.state.phase === 1 ? <div><h1>Mots à retenir avec la prononciation</h1><p>Vous devez simplement prononcer les mots à voix haute en anglais sur la page suivante (il faut accepter l'utilisation du micro).</p><p>Cliquer sur OK quand vous avez mémorisé les mots avec leurs prononciations.</p>
    <ListeMots donnees={this.dataJeu.info} finMot={this.finMot} texte="OK">
        </ListeMots></div> :!this.state.afficheResultat ? <div><h1>{'Parler en anglais : ' + this.dataJeu.titre}</h1>
        <h2>Prononcez les mots en anglais</h2>
        <p>La reconnaissance vocale démarre automatiquement dès l'affichage du mot. Attendez que le point rouge s'affiche dans l'onglet pour commencer à parler. Vous pouvez prononcer n'importe quel mot pour arrêter l'enregistrement.</p>
        <div><Compteur noQuestion={this.noQuestion + 1} nbQuestion={this.nbQuestion}></Compteur></div>
       
        <div className={"centre titre " + this.state.animationMot}>{this.state.motF}</div>
        <div className='margeHaut20 centre grandeFont '>{this.state.motParle}</div>
            {this.state.aideErreur && <div className="margeHaut10">{this.afficheAide()}</div>}
     </div>
    : (this.data.concours ? <FinEtape nom={this.nom} donneesJeu={this.data.jeu} nbFautes={this.stat.resultat.nbFautes}></FinEtape>:<Resultat nom={this.nom} id={this.id} statistique={this.stat} donnees={this.data}></Resultat>) }
    </React.Fragment>
    }
}