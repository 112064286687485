import React, { Component } from 'react';
import env from '../../components/commun/config';
import { Redirect } from 'react-router-dom';
import DataAccess from '../../data/DataAccess';
import LogiqueQcm from './LogiqueQcm';
import Reponses from './Reponses';
import { message} from 'antd';
import Resultat from '../../components/commun/Resultat';
import Statistique from '../../components/commun/Statistique';
import FinEtape from '../concours/FinEtape';

import {Helmet } from 'react-helmet';
import { addGame } from '../../components/commun/localStorage';
import { Compteur } from '../commun/Aide';


export default class JeuQcm extends Component
{
    constructor(props)
    {
        super(props);
        this.id = parseInt(props.match.params.id);
        this.stop = false;
        this.data = new DataAccess(env.nomQcm, this.id);
        this.dataJeu = this.data.obtenirDonneesJeuMelange();
        if (this.dataJeu === undefined) {
            this.stop = true;
        }
        else {
            this.nom = env.nomQcm;
            this.noPhrase = 0;
            this.infoJeu = LogiqueQcm.construirePhrase(this.dataJeu.info[this.noPhrase].phrase);
            this.state= {
                phrase :this.infoJeu.phraseHtml,
                afficheResultat: false,
                reponses : this.dataJeu.info[this.noPhrase].reponses,
                noQuestion: this.noPhrase+1
            }
            this.reponseEnCours = false;
            this.nbQuestion = this.dataJeu.info.length;
            this.stat = new Statistique(this.nbQuestion);
            addGame(this.nom, this.id);
           
        }
    }

    miseAJourMot =() =>
    {
      
        this.infoJeu = LogiqueQcm.construirePhrase(this.dataJeu.info[this.noPhrase].phrase);
        this.setState({
            phrase : this.infoJeu.phraseHtml,
            reponses : this.dataJeu.info[this.noPhrase].reponses,
            noQuestion: this.noPhrase+1
        }
        )
    }

    suivant = () =>
    {
        this.reponseEnCours = false;
        this.noPhrase++;
        if (this.noPhrase < this.dataJeu.info.length)
        {
            this.miseAJourMot();
        }
        else
        {
            this.setState({ afficheResultat: true});
        }
    }

    selectionMot = (id) =>
    {
        if (this.reponseEnCours) return;
        this.reponseEnCours = true;
        if (this.infoJeu.mot === this.dataJeu.info[this.noPhrase].reponses[id])
        {
            message.success('Well done!', 1,this.suivant);
        }
        else
        {
            this.stat.ajouterFaute();
            message.error('Sorry, that is incorrect.',3, this.suivant);
        }
        let nouvellePhrase = this.state.phrase.replace('motCache', 'motVisible');
        this.setState({phrase: nouvellePhrase});
    }

    render()
    {
        if (this.stop) {
            return (<Redirect to='/'></Redirect>);
        }
        return  <div>
             <Helmet>
          <title>Exercices de grammaire en anglais</title>
          <meta name="description" content="Des tests de grammaire anglaise dans lesquels vous devez choisir la bonne réponse parmi plusieurs propositions."/>
                 </Helmet>

            {!this.state.afficheResultat ?<React.Fragment><h1>Choisir la bonne proposition</h1>
                {this.data.jeu.aide != null && <p><b className="aide">Information </b> : <span dangerouslySetInnerHTML={{ __html: this.data.jeu.aide }}></span></p>}
            <div><Compteur noQuestion={this.state.noQuestion} nbQuestion={this.nbQuestion}></Compteur></div><div className="qcmGlobal margeHaut20"><div dangerouslySetInnerHTML={{ __html: this.state.phrase }}></div>
        <div className="margeHaut60"><Reponses mots={this.state.reponses} selectionMot={this.selectionMot}></Reponses></div>
        </div></React.Fragment>:  (this.data.concours ? <FinEtape nom={this.nom} donneesJeu={this.data.jeu} nbFautes={this.stat.resultat.nbFautes}></FinEtape>:<Resultat nom={this.nom} id={this.id} statistique={this.stat} donnees={this.data}></Resultat>)}
        </div>
    }
} 