import React, { Component } from 'react';

export default class Audio extends Component {

    constructor(props) {
        super();
        this.audio = React.createRef();
        this.fichierEnCours = 0;
        this.state =
        {
            fichier: props.fichiers[0],
            etat: 'boutonPlayCode'
        }
    }
    componentDidMount() {


        let promise = this.audio.current.play();

        if (promise !== undefined) {
            promise.catch(error => {
                console.log("pas démarré");
                this.setState({ etat: 'boutonPlayCode pausedCode' });
            });
        };
    }

    finAudio = () => {
        this.fichierEnCours++;
        if (this.fichierEnCours < this.props.fichiers.length) {
            this.setState({ fichier: this.props.fichiers[this.fichierEnCours] })
        }
        else {
            this.setState({ fichier: this.props.fichiers[0], etat: 'boutonPlayCode pausedCode' });

        }

    }
    demarreAudio = () => {
        if (this.fichierEnCours < this.props.fichiers.length) {
            if (this.audio.current.paused) {
                this.audio.current.play();
            }
        }
        else {
            this.fichierEnCours = 0;
        }

    }

    clic = () => {
        if (this.audio.current.paused) {
            this.audio.current.play();
            this.setState({ etat: 'boutonPlayCode' });
        }
        else {
            this.audio.current.pause();
            this.setState({ etat: 'boutonPlayCode pausedCode' });
        }

    }
    render() {
        return <div><button className={this.state.etat} onClick={this.clic} ></button><audio style={{ width: '300px' }}
            onLoadedData={this.demarreAudio}
            ref={this.audio}
            src={this.state.fichier}
            onEnded={this.finAudio}>
        </audio>
        </div>

    }
}