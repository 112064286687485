import React, { Component } from 'react';
import Saisie from './Saisie';
import LogiquePhraseTrou from './Logique';
import Resultat from '../../components/commun/Resultat';
import { Row, message } from 'antd';
import { addGame } from '../../components/commun/localStorage';
import FinEtape from '../concours/FinEtape';
import { withRouter, Redirect } from 'react-router-dom';
import Statistique from '../../components/commun/Statistique';
import { Helmet } from 'react-helmet';

import Audio from '../commun/Audio';
import env from '../../components/commun/config';



class PhraseTrou extends Component {

    constructor(props) {
        super();
        const id = parseInt(props.match.params.id);
      
        this.numeroPhrase = id;
        this.stop = false;
        this.nom = env.nomPhraseTrou;
        this.logiquePhrase = new LogiquePhraseTrou(this.numeroPhrase);
            if (isNaN(id) || this.logiquePhrase.texte == null) {
            this.stop = true;
        }
        else {
            this.tabTexte = this.logiquePhrase.ConstruireTableauPhrase();
            this.nbMotTotal = this.logiquePhrase.CalculNombreReponse();
            this.stat = new Statistique(this.nbMotTotal);
            
            this.state = { nbFautes: 0, nbMots: 0, fin: false,
                nomFichier: '/audio/' + this.logiquePhrase.audio, };
            addGame('phraseTrou', this.numeroPhrase);
      
        }
    }

    finJeu = () => {
        this.setState({ fin: true });
    }

    afficherCompteur = (resultat) => {
        let nbFautes = this.state.nbFautes;
        if (resultat === 'erreur') {
            nbFautes++;
            this.stat.ajouterFaute();
            this.setState({ nbFautes: nbFautes });
        }

        if (this.state.nbMots + 1 === this.nbMotTotal) {
            message.success("Nombre de fautes : " + nbFautes, 2, this.finJeu)
        }
        this.setState({ nbMots: this.state.nbMots + 1 });

    }


    render() {
        if (this.stop) return (<Redirect to='/'></Redirect>);

        return (<div>
            <Helmet>
                <title>Test d'anglais : compléter les phrases</title>
                <meta name="description" content="Tester votre niveau en anglais en complétant les mots manquants dans ces exercices de phrases avec des trous à compléter." />
            </Helmet>
            {this.state.fin ? <div>

{this.logiquePhrase.data.concours ? <FinEtape donneesJeu={this.logiquePhrase.data.jeu} nbFautes={this.state.nbFautes}></FinEtape> :
<Resultat nom={this.nom} id={this.numeroPhrase} statistique={this.stat} donnees={this.logiquePhrase.data}></Resultat>}</div>
           :<div> <h1 className="couleurTitre">Compléter les phrases</h1>
            <div>Compléter les mots manquants à partir de l'enregistrement audio. Vous pouvez réécouter le texte autant de fois que nécessaire. Tapez sur Entrée pour valider le mot.</div>
           
                
                <div>    <div className="tailleMoyenne"><b>{this.logiquePhrase.titre}</b></div>
                <div className="audioVocal"><Audio depart={true} nomFichier={this.state.nomFichier}></Audio></div>
                    {this.logiquePhrase.extrait != null && <div className="tailleMoyenne" dangerouslySetInnerHTML={{ __html: this.logiquePhrase.extrait }}></div>}
               <div>{'Nombre de faute' + (this.state.nbFautes > 1  ?'s : ' : ' : ') +  this.state.nbFautes}</div>
                    <div className="msgPhraseTrou">Appuyer sur <b> Espace </b> pour valider le mot</div>
                    <div className="fontStylePhraseTrou">{this.tabTexte.map((mot, i) => (this.logiquePhrase.verifierArrobase(mot) ? <Saisie afficherCompteur={this.afficherCompteur} mot={this.logiquePhrase.SupprimerArrobase(mot)} key={i}></Saisie> : <span key={i} dangerouslySetInnerHTML={{ __html: mot + ' ' }} ></span>))}
                    </div>

                 
                </div>
        </div>}
        </div>
    
        );
    }
}
export default withRouter(PhraseTrou);