
import SousMenu from './SousMenu.js';
import React, { Component } from 'react';
import { Row, Col, Card } from 'antd';
import env from '../commun/config.js';
import border from '../../images/border.png';
import SousMenuVoca from './SousMenuVoca.js';
import { Helmet } from 'react-helmet';
import Ad from '../commun/AdSense.js';
import { Link } from 'react-router-dom';
import { readListErrors } from '../commun/localStorage.js';
import Liens from './Liens.js';
import ClassementVocabulaire from '../commun/ClassementVocabulaire.js';

export default class Phrases extends Component {

    constructor()
    {
        super();

    }
    choixMessageListe = (nom, mot, langue) => {
        let resultat = readListErrors(nom);
        if (resultat.info.length === 0) {
            return <div>{"Vous n'avez pas encore de " + mot + " à revoir."}</div>

        }
        else {

            let motLangue = resultat.info.map(mot => langue === 'f' ? mot.f : mot.a);
            return <div>{motLangue.map((mot, i) => <span key={i + langue} className="margeDroite">{mot.length > 12 ? mot.substring(0, 12) + '...' : mot}</span>)}<div className="grandeFont centre"><Link to={nom + '/9999'}>Revoir ces phrases</Link></div></div>

        }

    }
    render() {
        return <div>
                    <Helmet>
          <title>Traduire des phrases en anglais</title>
          <meta name="description" content="Des tests pour s'entrainer à traduire de petites phrases en anglais."/>
                 </Helmet>
            <div className="centre"><img src={border} alt="bordure"></img></div>
            <h1 className="titre centre">Traduire des phrases en anglais</h1>


<div className="centre"><img src={border} alt="bordure"></img></div>
            <Row gutter={{ md: 16 }} className="margeHaut">

                <Col md={24}>
                    <h2>Traduire de petites phrases du français vers l'anglais</h2>
                    <p>Dans ces tests il faut traduire de courtes phrases du français vers l'anglais. La ponctuation en fin de phrase n'est pas obligatoire.
                    </p>
                     <p>Tu peux trouver toutes les phrases à traduire de ces tests dans notre  <Link to='phrase-anglais'>lexique des phrases à traduire du français vers l'anglais</Link>.</p>
                <p>Si tu veux faire les tests dans l'autre sens, tu peux aller sur notre page <Link to='/test-phrases-english'>anglais vers français</Link>. </p>

                </Col>
            </Row>
            <Row gutter={{ md: 16 }}>
                <Col md={12}>
              
                <SousMenuVoca type='phrase' langue='francais' nom={env.obtenirNomComplet('francais', 'phrase')} titre="Phrases français->anglais"></SousMenuVoca>

                    <Card className="margeHaut20 fondCard">
                      <div><b>Liste des phrases que tu ne maitrises pas encore.</b></div>
                        {this.choixMessageListe(env.obtenirNomComplet('francais', 'phrase'), 'phrases', 'f')}
                    </Card>
                </Col>
                

            </Row>
        <Ad></Ad>
           
        <ClassementVocabulaire type='4'nom={env.obtenirNomComplet('francais', 'phrase')} ></ClassementVocabulaire>


           
            <div className="centre"><img src={border} alt="bordure"></img></div>
            <Liens></Liens>
           
        </div>
    }
}