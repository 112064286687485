
import React, {Component} from 'react';
import {Button} from 'antd';
import Audio from './Audio';

export default class ListeMots extends Component
{


    fin = () =>
    {
        this.props.finMot();
    }
    render()
    {
    return <div><table style={{margin:'auto'}}><tbody className="listeMot">{this.props.donnees.map((mot,i) => <tr key={i}><td>{mot.texteA}</td><td>{mot.texteF}</td><td>
        <Audio   src={'/audio/' + mot.audio}> </Audio>
</td></tr>)}
    </tbody></table>   <div className="centre margeHaut20"><Button  className="margeBas10" type="primary" onClick={this.fin}>{this.props.texte}</Button></div></div>
    }
}