import React, { Component } from 'react';
import { transformSentence } from '../commun/logiqueTraduction';
import FinEtape from '../concours/FinEtape';

import DataAccess from '../../data/DataAccess';
import Resultat from '../../components/commun/Resultat';
import Saisie, { typeAction } from '../commun/Saisie';
import { message, Button } from 'antd';
import Statistique from '../../components/commun/Statistique';
import { Redirect } from 'react-router-dom';
import { addGame } from '../../components/commun/localStorage';
import {Compteur} from '../commun/Aide';

import {Helmet } from 'react-helmet';
import env from '../../components/commun/config';



export default class JeuVocabulaire extends Component {

    constructor(props) {
        super();
        this.id = parseInt(props.match.params.id);
        this.stop = false;
        this.data = new DataAccess(env.nomConjugaison, this.id);
        this.dataJeu = this.data.obtenirDonneesJeuMelange();
        if (this.dataJeu === undefined) {
            this.stop = true;
        }
        else {

           
            this.nom = env.nomConjugaison;
            this.noQuestion = 0;
            this.nbQuestion = this.dataJeu.info.length;
            this.state = {
                question: this.dataJeu.info[this.noQuestion],
                afficheErreur: false,
                afficheResultat: false,
                questionEnCours : 0,
                mot : ''
            }
            this.stat = new Statistique(this.nbQuestion);
            this.reponseEnCours = false;
            addGame(this.nom, this.id);
   
        }
    }

    change = (mot) =>
    {
        this.setState({mot});
    }

    finMessageReussi = () => {
        this.noQuestion++;
        this.reponseEnCours = false;
        if (this.noQuestion === this.nbQuestion) {
            this.setState({ afficheResultat: true });
        }
        else {
            this.setState({questionEnCours: this.noQuestion,  afficheErreur: false, question: this.dataJeu.info[this.noQuestion], mot:'' });
        }
       
    }

    finMessageErreur = () => {
        this.reponseEnCours = false;
    }

    passer = () =>
    {
        this.stat.ajouterFaute();
        this.finMessageReussi();
    }


    verifierReponse = () => {
        if (!this.reponseEnCours) {
            this.reponseEnCours = true;
            if (transformSentence(this.state.mot) === transformSentence(this.state.question.reponse) || (this.state.question.reponsec != null && transformSentence(this.state.mot) === transformSentence(this.state.question.reponsec))) {
                message.success("Bravo", 1, this.finMessageReussi);
                return typeAction.focusReset;
            }
            else {
                message.error("Vous avez fait une erreur", 2, this.finMessageErreur);
                this.setState({
                    afficheErreur: true
                });
                this.stat.ajouterMotEtFaute(this.state.question.reponse);
                return typeAction.focus;
            }
        }
    }



    render() {

        if (this.stop) {
            return (<Redirect to='/'></Redirect>);
        }
    return <div>
           <Helmet>
          <title>Conjugaison des verbes en anglais</title>
          <meta name="description" content="Des tests faciles, gratuits et en ligne pour apprendre ou réviser les différents temps et verbes de la langue anglaise (passé, présent, futur...)."/>
                 </Helmet>
            
        
                 {!this.state.afficheResultat ?<div><h1>Test de conjugaison des verbes anglais</h1>
            <div><Compteur noQuestion={this.state.questionEnCours+1} nbQuestion={this.nbQuestion}></Compteur></div>
           
            <div className="questionConj moyenneFont"><div className="violet">{this.state.question.temps}</div><div> {this.state.question.forme}</div><div className="grandeFont"><span>{this.state.question.sujet}</span><span className="margeGauche15">{this.state.question.verbe }</span></div></div>
            <div className="saisieConj"><Saisie verifierReponse={this.verifierReponse} mot={this.state.mot} change={this.change}></Saisie></div>
               {this.state.afficheErreur && <div className="reponseVoc">
                <p className="titreReponseVoc">Réponse :</p><div className="erreurConj">{this.state.question.reponse}</div>{this.state.question.reponsec != null && <div><div>ou</div><div className="erreurConj">{this.state.question.reponsec}</div></div>} 
                <p className="messageVoc">Veuillez saisir la bonne réponse.</p>
                <div><Button onClick={this.passer}>Passer</Button><span> (+1 faute)</span></div>
            </div>}
        </div> : (this.data.concours ? <FinEtape nom={this.nom} donneesJeu={this.data.jeu} nbFautes={this.stat.resultat.nbFautes}></FinEtape>:<Resultat nom={this.nom} id={this.id} statistique={this.stat} donnees={this.data}></Resultat>)}</div>
    }
}