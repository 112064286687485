

export default class Logique {

    static modifierLettres(tabMots, reponse, numero) {
        Logique.modifierMotTrouve(tabMots, numero);
        let tabReponse = reponse.split('');
        let tabMotsOrdonnee = Logique.modifierOrdreMots(tabMots, numero);

        for (const lettre of tabReponse) {
            for (const mots of tabMotsOrdonnee) {
                if (!mots.trouve) {
                    let index;
                    for (index = 0; index < mots.tabLettresMot.length; index++) {

                        if (mots.tabLettresMot[index] === lettre && mots.tabLettresUtilisateur[index] === '') {
                            mots.tabLettresUtilisateur[index] = lettre;
                            break;
                        }
                    }
                    if (index !== mots.tabLettresMot.length) {
                        break;
                    }
                }

            }
        }
    }

    static modifierOrdreMots(tabMots, numero) {
        let fin = false
        let i = 0;
        let tabMotsOrdonne = [];
        let retour = numero;
        while (!fin) {
            numero++;
            if (numero === tabMots.length) {
                numero = 0;
            }

            if (numero === retour) {
                fin = true;
            }
            else {
                tabMotsOrdonne[i] = tabMots[numero];
                i++;
            }

        }
        return (tabMotsOrdonne);
    }

    static modifierMotTrouve(tabMots, numero) {

        tabMots[numero].tabLettresUtilisateur = [...tabMots[numero].tabLettresMot];

    }

    static supprimerMotsTrouve(tabMots) {

        for (const mot of tabMots) {
            if (!mot.trouve)
            {
                if ( mot.tabLettresUtilisateur.filter(l => l === '').length === 0)
                {
                    mot.trouve = true;
                }
            }
        }
    }

    static estGagnant(tabMots)
    {
      return  tabMots.filter(x => !x.trouve).length === 0;
    }

}