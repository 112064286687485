const dataQcm =
    [
        {
            id: 1,
            titre: "Prépositions 1",
            niveau: 3,
            info: [
                {
                    phrase: "My brother is afraid @of@ spiders.",
                    reponses: ["about", "of", "from", "to"]
                },
                {
                    phrase: "My aunt is married @to@ a policewoman.",
                    reponses: ["to", "with", "of", "in"]
                },
                {
                    phrase: "Alice was fed up @with@ her job.",
                    reponses: ["of", "for", "to", "with"]
                },
                {
                    phrase: "Your homework is full @of@ mistakes.",
                    reponses: ["in", "on", "with", "of"]
                }, 
                {
                    phrase: "Don’t disturb me. I am busy @with@ my project.",
                    reponses: ["in", "on", "of", "with"]
                },
                {
                    phrase:"French people are famous @for@ their hospitality.",
                    reponses: ["to", "for", "of", "with"]
                }

                
            ]

        }
        ,
        {
            id: 2,
            titre: "Prépositions 2",
            niveau: 3,
            info: [
                {
                    phrase: "I'm not scared @of@ spiders.I just find them disgusting.",
                    reponses: ["about", "of", "from", "with"]
                },
                {
                    phrase: "I'm bad @at@ drawing pictures, but I like visiting museums.",
                    reponses: ["to", "in", "at", "for"]
                },
                {
                    phrase: "I'm not interested @in@ sport." ,
                    reponses: ["at", "for", "in", "with"]
                },
                {
                    phrase: "I'm fond @of@ music.",
                    reponses: ["in", "on", "with", "of"]
                }, 
                {
                    phrase: "I'm still angry @with@ Tom.",
                    reponses: ["to", "on", "of", "with"]
                },
                {
                    phrase:" They have English lessons @on@ Friday.",
                    reponses: ["on", "at", "for", "about"]
                }

                
            ]

        },
        {
            id: 3,
            titre: "Prépositions 3",
            niveau: 4,
            info: [
                {
                    phrase: "  Thank you for your help! It is very kind @of@ you!",
                    reponses: ["about", "of", "at", "with"]
                },
                {
                    phrase: " The plane is going to take @off@ in few minutes.",
                    reponses: ["up", "by", "at", "off"]
                },
                {
                    phrase: " It is not easy to cope @with@ all my problems." ,
                    reponses: ["at", "for", "in", "with"]
                },
                {
                    phrase: "She should not shout @at@ him.",
                    reponses: ["at", "on", "with", "by"]
                }, 
                {
                    phrase: " She always gets @into@ trouble.",
                    reponses: ["into", "on", "off", "with"]
                },
                {
                    phrase:" He had started ballet classes but after a few lessons he went @off@.",
                    reponses: ["at", "into", "for", "off"]
                }

                
            ]

        },
        {
            id: 4,
            titre: "Prépositions sur le temps",
            niveau: 3,
            info: [
                {
                    phrase: "She lived in London @in@ 1911.",
                    reponses: ["on", "in", "at", "to"]
                },
                {
                    phrase: "@At@ Easter the weather is mild.",
                    reponses: ["In", "On", "At", "To"]
                },
                {
                    phrase: " I never go out @at@ night." ,
                    reponses: ["at", "for", "in", "to"]
                },
                {
                    phrase: "My father’s birthday is @on@ September 27th.",
                    reponses: ["at", "for", "in", "on"]
                }, 
                {
                    phrase: "The students don’t have school @in@ July.",
                    reponses: ["to", "on", "at", "in"]
                },
                {
                    phrase:"I don’t have English lessons @on@ Wednesday.",
                    reponses: ["on", "at", "for", "about"]
                }

                
            ]
        },
        {
            id: 5,
            titre: "Prépositions de position",
            aide : "Des informations sur la différence entre <a href='https://grammar.collinsdictionary.com/english-usage/what-is-the-difference-between-under-below-and-beneath' target=”_blank” rel=”noopener noreferrer”> under et below (in English)</a>.",
            niveau: 3,
            info: [
                {
                    phrase: "I am @under@ 18. So, I can't vote yet.",
                    reponses: ["under", "below"]
                },
                {
                    phrase: "The book is @under@ the table.",
                    reponses: ["under", "below"]
                },
                {
                    phrase: "We walked @along@ the river." ,
                    reponses: ["below", "around", "along"]
                },
                {
                    phrase: "Your nose is @below@ your eyes.",
                    reponses: ["above", "over", "under", "below"]
                }, 
                {
                    phrase: "When the ship sank, they jump @into@ the water.",
                    reponses: ["to", "at", "into", "on"]
                },
                {
                    phrase:"The book is @on@ the shelf.",
                    reponses: ["to", "at", "for", "on"]
                }

                
            ]
        },
        {
            id: 6,
            titre: "much/many",
            aide : "Rappel de cours rapide sur l'utilisation de <a href='https://www.gymglish.com/fr/gymglish/grammaire-anglaise/much-vs-many' target=”_blank” rel=”noopener noreferrer”> much/many</a>.",
            niveau: 2,
            info: [
                {
                    phrase: "You need @much@ time to achieve your project.",
                    reponses: ["much", "many"]
                },
                {
                    phrase: "I don't earn @much@ money with this company.",
                    reponses: ["much", "many"]
                },
                {
                    phrase: "How @many@ people are there?",
                    reponses: ["much", "many"]
                },
                {
                    phrase: "How @much@ sugar did you buy?",
                    reponses: ["much", "many"]
                }, 
                {
                    phrase: "You didn't give me @much@ information.",
                    reponses: ["much", "many"]
                },
                {
                    phrase:"How @much@ are three cupcakes?",
                    reponses: ["much", "many"]
                }

                
            ]
        },
        {
            id: 7,
            titre: "few/little",
            aide : "Rappel de cours rapide sur l'utilisation de <a href='https://www.gymglish.com/fr/gymglish/grammaire-anglaise/few-little' target=”_blank” rel=”noopener noreferrer”> few/little</a>.",
            niveau: 2,
            info: [
                {
                    phrase: "Alice is very busy. She has @little@ free time.",
                    reponses: ["little", "few"]
                },
                {
                    phrase: "There were @few@ people in the supermarket.",
                    reponses: ["little", "few"]
                },
                {
                    phrase: "The weather has been very dry recently. We've had @little@ rain.",
                    reponses:  ["little", "few"]
                },
                {
                    phrase: "We’ve got a @few@ books.",
                    reponses:  ["little", "few"]
                }, 
                {
                    phrase: "There's only a @little@ pasta left.",
                    reponses:  ["little", "few"]
                },
                {
                    phrase:"There are only a @few@ sausages in the fridge,",
                    reponses:  ["little", "few"]
                }    
            ]
        },
        {
            id: 8,
            titre: "some/any",
            aide : "Rappel de cours rapide sur l'utilisation de <a href='https://www.gymglish.com/fr/gymglish/grammaire-anglaise/some-et-any' target=”_blank” rel=”noopener noreferrer”> some/any</a>.",
            niveau: 1,
            info: [
                {
                    phrase: "There aren't @any@ children in the school.",
                    reponses: ["some", "any"]
                },
                {
                    phrase: "Can I have @some@ sugar, please?",
                    reponses: ["some", "any"]
                },
                {
                    phrase: "I don't have @any@ coffee at home.",
                    reponses: ["some", "any"]
                },
                {
                    phrase: "Do you want @some@ money ?",
                    reponses: ["some", "any"]
                }, 
                {
                    phrase: "I don’t want @any@ cream with my tea.",
                    reponses: ["some", "any"]
                },
                {
                    phrase:"I need @some@ sugar for my coffee.",
                    reponses: ["some", "any"]
                }    
            ]
        },
        {
            id: 9,
            titre: "Les comparatifs",
            aide : "Rappel de cours rapide sur l'utilisation de <a href='https://multigram.ulb.ac.be/eng/Adjectif:_degr%C3%A9s_de_comparaison' target=”_blank” rel=”noopener noreferrer”> la comparaison</a>.",
            niveau: 2,
            info: [
                {
                    phrase: "Ryan Gosling  is @the most attractive@ actor in the world.",
                    reponses: ["the most attractive", "the more attractive"]
                },
                {
                    phrase: "A bicycle is @cheaper@ than a plane.",
                    reponses: ["more cheap", "cheaper"]
                },
                {
                    phrase: "Nike is @the most famous@ sport brand.",
                    reponses: ["the more famous", "the most famous"]
                },
                {
                    phrase: "Motorcycles are @more dangerous@ than bicycles.",
                    reponses: ["most dangerous", "more dangerous"]
                }, 
                {
                    phrase: "Mount Everest is @taller@ than Mont Blanc.",
                    reponses: ["taller", "more tall"]
                },
                {
                    phrase:"Alice gets to work @earlier@ than I do.",
                    reponses: ["earlier", "more early"]
                }    
            ]
        },
        {
            id: 10,
            titre: "verbe/infinitif/gérondif",
            niveau: 3,
            info: [
                {
                    phrase: "My brother enjoys @singing@ very much.",
                    reponses: ["singing", "to sing", "sing"]
                },
                {
                    phrase: "My sister doesn't want @to dance@ with Tom.",
                    reponses: ["to dance", "dancing", "dance"]
                },
                {
                    phrase: "I don't expect you @to do@ all the work for me.",
                    reponses: ["to do", "doing", "do"]
                },
                {
                    phrase: "John hates @flying@.",
                    reponses: ["to fly", "flying"]
                }, 
                {
                    phrase: "They should @go@ to the doctor.",
                    reponses: ["to go", "go", "going"]
                },
                {
                    phrase:"You must give up @trying@.",
                    reponses: ["to try", "trying", "try"],
                }    
            ]
        },
        {
            id: 11,
            titre: "must/have to",
            aide : "Rappel de cours rapide sur l'utilisation de <a href='https://multigram.ulb.ac.be/eng/Must' target=”_blank” rel=”noopener noreferrer”> must/have to</a>.",
            niveau: 3,
            info: [
                {
                    phrase: "I @have to@ do my homework. My mother always checks if I have done the exercises.",
                    reponses: ["have to", "must"]
                },
                {
                    phrase: " I @must@ study hard if I want to pass the exam.",
                    reponses: ["must", "have to"]
                },
                {
                    phrase: "They @mustn't@ shout in a library.",
                    reponses: ["mustn't", "don't have to"]
                },
                {
                    phrase: "Police officers @must@ wear a uniform.",
                    reponses: ["have to", "must"]
                }, 
                {
                    phrase: "I @have to@ buy a new oven, the one I have is broken.",
                    reponses: ["must", "have to"]
                },
                {
                    phrase:"I don't like it but I @have to@ go to school every day.",
                    reponses: ["must", "have to"],
                }    
            ]
        },
        {
            id: 12,
            titre: "used to",
            aide : "Rappel de cours rapide sur l'utilisation de <a href='https://multigram.ulb.ac.be/eng/Used_to,_Would' target=”_blank” rel=”noopener noreferrer”> used to et to be used to</a>.",
            niveau: '4',
            info: [
                {
                    phrase: "I @used to run@ but I gave it up when we moved to the London.",
                    reponses: ["used to run", "got used to running"]
                },
                {
                    phrase: "She doesn’t think Tom is strange. She @is used to@ him.",
                    reponses: ["used to", "is used to"]
                },
                {
                    phrase: "You come from a big city. You will never @get used to living@ in a small village",
                    reponses: ["get used to living", "used to live"]
                },
                {
                    phrase: "My sister has been a doctor for 7 years. She @is used to working@ at night.",
                    reponses: ["gets used to working", "is used to working"]
                }, 
                {
                    phrase: "When I was a child, I @used to play@ in the street every day.",
                    reponses: ["got used to playing", "used to play"]
                },
                {
                    phrase:"I hated this haircut at first. But I @got used to@ it.  I like it now!",
                    reponses: ["got used to", "was used to"],
                }    
            ]   
        },
        {
            id: 13,
            titre: "Les adjectifs possessifs (his,her,its...)",
            aide : "Rappel de cours rapide sur l'utilisation des <a href='https://multigram.ulb.ac.be/eng/Adjectif_possessif' target=”_blank” rel=”noopener noreferrer”> adjectifs possessifs</a>.",
            niveau: '3',
            info: [
                {
                    phrase: "He kissed @his@ mother.",
                    reponses: ["his", "her"]
                },
                {
                    phrase: "She received @her@ diploma.",
                    reponses: ["their", "its", "her"]
                },
                {
                    phrase: "They left @their@ car in the underground car park.",
                    reponses: ["its", "her", "their"]
                },
                {
                    phrase: "Everybody brought @their@ umbrellas and raincoats.",
                    reponses: ["its", "her", "their"]
                }, 
                {
                    phrase: "Somebody forgot @their@ key here.",
                    reponses: ["their", "her", "his", "its"]
                },
                {
                    phrase:"He lost @his@ life in a plane accident.",
                    reponses: ["his", "her", "its"],
                }    
            ]  
        },
        {
            id: 14,
            titre: "Les pronoms possessifs (his,hers,ours...)",
            aide : "Rappel de cours rapide sur l'utilisation des <a href='https://multigram.ulb.ac.be/eng/Pronom_possessif' target=”_blank” rel=”noopener noreferrer”> pronoms possessifs</a>.",
            niveau: '2',
            info: [
                {
                    phrase: "This coat is @mine@.",
                    reponses: ["my", "mine"]
                },
                {
                    phrase: "My umbrella is red. What colours is @yours@?",
                    reponses: ["your", "yours"]
                },
                {
                    phrase: "Is this Emmy's book? Yes, it's @hers@.",
                    reponses: ["her", "hers", "their"]
                },
                {
                    phrase: "That's an old friend of @ours@.",
                    reponses: ["our", "ours", "its", "it"]
                }, 
                {
                    phrase: "Tom: My parents are American. Tim: @Mine@ too",
                    reponses: ["Their", "Mine", "My", "Its"]
                },
                {
                    phrase:"Can I borrow your computer this weekend? No, because @mine@ has broken down.",
                    reponses: ["mine", "their", "my", "theirs"],
                }    
            ]  
        },

    ]
export default dataQcm;