import React, { Component } from 'react';
import Audio from '../commun/Audio';
import DataAccess from '../../data/DataAccess';
import Saisie from '../voca500/Saisie';
import Lettres from '../voca500/Lettres';
import { Button, message } from 'antd';
import Resultat from '../../components/commun/Resultat';
import Statistique from '../../components/commun/Statistique';
import { Redirect } from 'react-router-dom';
import { addGame } from '../../components/commun/localStorage';
import { Compteur } from '../commun/Aide';
import audio from '../../images/boutonAudio.JPG';

import { Helmet } from 'react-helmet';
import env from '../../components/commun/config';
import FinEtape from '../concours/FinEtape';




export default class JeuEcoute extends Component {

    constructor(props) {
        super();
        this.id = parseInt(props.match.params.id);
        this.stop = false;
        this.data = new DataAccess(env.nomEcoute, this.id)
        this.dataJeu = this.data.obtenirDonneesJeuMelange();
        this.nbErreursFaute = 5;
        this.fin = false;
        this.erreursMot = 0;
        if (this.dataJeu === undefined) {
            this.stop = true;
        }
        else {
            this.nom = env.nomEcoute;
            this.noQuestion = 0;

            this.state = {
                tabLettresReponse: [],
                reponse: 'aucune',
                nomFichier: '',
                afficheResultat: false,
                questionEnCours: 0,
                traduction : '',
                audioDemmare: true,
                afficheAudio: true
            };
            this.nbQuestion = this.dataJeu.info.length;

            this.stat = new Statistique(this.nbQuestion * 2);
            this.reponseEnCours = false;
            this.child = React.createRef();
            addGame(this.nom, this.id);
  

        }

    }

    nouveauMot = () => {
        if(this.noQuestion === this.dataJeu.info.length)
        {
            this.setState({afficheResultat: true});
            return;
        }
        this.fin = false;
        this.erreursMot = 0;
        let mots = this.dataJeu.info[this.noQuestion].a;
        mots = mots.trim();
        let lastElement = mots.charAt(mots.length - 1);
        let regPunctuation = /[,;:.!?\\-]/;

        if (lastElement.match(regPunctuation)) {
            mots = mots.slice(0, mots.length - 1).trim();
            let lettresReponse = mots.split('');
            let lettresEnCours = new Array(lettresReponse.length).fill('');
            let tabLettresReponse = lettresReponse.map((lettre, i) => { return { lettre, id: i, lettreEnCours: lettresEnCours[i], couleurLettre: lettre === ' ' ? "vide500" : "orange500" } });

            this.setState({
                tabLettresReponse,
                nomFichier: '/audio/' + this.dataJeu.info[this.noQuestion].audio,
                traduction: '',
                questionEnCours: this.noQuestion,
                afficheAudio: true
            })
            this.child.current.reset();
            this.child.current.setFocus();

        }
    }


    componentDidMount = () => {
        this.nouveauMot();

    }

    audioDepart = () => {
        this.setState({ audioDemmare: false });
    }



    change = (mot) => {
        if (this.fin) return;
        let nouveauTabLettre = [...this.state.tabLettresReponse];
        let motJoueur = mot.split('');
        let longueur = motJoueur.length >= this.state.tabLettresReponse.length ? this.state.tabLettresReponse.length : motJoueur.length;
        let nbBon = 0;
        for (let index = 0; index < longueur; index++) {
            const lettre = motJoueur[index];

            if (nouveauTabLettre[index].lettre === lettre) {
                nouveauTabLettre[index].couleurLettre = 'vert500';
                nbBon++;
            }
            else {
                nouveauTabLettre[index].couleurLettre = 'rouge500';
                this.erreursMot++;
            }
            nouveauTabLettre[index].lettreEnCours = motJoueur[index];
        }
        if (nbBon === nouveauTabLettre.length && motJoueur.length === nouveauTabLettre.length) {

            this.fin = true;
            if (this.erreursMot > this.nbErreursFaute) {
                this.stat.ajouterFaute();
            }
            this.setState({traduction: this.dataJeu.info[this.noQuestion].f, afficheAudio: false });

            this.noQuestion++;
             message.success('Ok', 2, this.nouveauMot);
        }
        else {
            for (let index = longueur; index < nouveauTabLettre.length; index++) {
                nouveauTabLettre[index].couleurLettre = nouveauTabLettre[index].lettre === ' ' ? "vide500" : "orange500";
                nouveauTabLettre[index].lettreEnCours = '';

            }
        }
        this.setState({ tabLettresReponse: nouveauTabLettre });
    }


    clicPasser = () => {
        if (this.fin) return;
        this.stat.ajouterFaute();
        let nouveauTabLettre = [...this.state.tabLettresReponse];
        for (let index = 0; index < nouveauTabLettre.length; index++) {

            nouveauTabLettre[index].lettreEnCours = nouveauTabLettre[index].lettre;
            nouveauTabLettre[index].couleurLettre = 'vert500';
        }
        this.fin = true;
        message.error('Erreur', 2, this.nouveauMot);
        this.setState({ tabLettresReponse: nouveauTabLettre, traduction: this.dataJeu.info[this.noQuestion].f, afficheAudio: false });
        this.noQuestion++;
    }





render() {
    if (this.stop) {
        return (<Redirect to='/'></Redirect>);
    }
    return <div>
        <Helmet>
            <title>{"Test pour comprendre une phrase anglaise : " + this.dataJeu.titre}</title>
            <meta name="description" content="Des tests pour évaluer son niveau de compréhension de phrases anglaises à l'oral. Dans un premer temps vous devez recopier la phrase anglaise entendue puis ensuite donner la traduction française. Le niveau des tests va de A1 à B2." />
        </Helmet>


        {!this.state.afficheResultat ? <React.Fragment><h1>{"Transcription de l'oral vers l'écrit : " + this.dataJeu.titre}</h1>
            <p>Écrire la phrase sans la traduire. Pas de majuscule en début de phrase (sauf pour I). Vous pouvez réécouter la phrase autant de fois que vous le désirez.</p>
            {!this.state.audioDemmare && <p>Cliquez sur le bouton Play <img src={audio} alt="bouton audio"></img> du lecteur audio pour écouter la phrase.</p>}
            <Compteur noQuestion={this.state.questionEnCours + 1} nbQuestion={this.nbQuestion}></Compteur><div className="gridVocal"> <div className="audioVocal">

                {this.state.afficheAudio && <Audio audioDepart={this.audioDepart} depart={true} nomFichier={this.state.nomFichier}></Audio>}</div><React.Fragment>
                    {this.state.reponse === 'anglais' ? <div className="phraseAnglaisVocal moyenneFont"><div className={this.state.motErreur === '' ? 'couleurOK' : 'couleurKO'}>{this.dataJeu.info[this.noQuestion].a}</div>
                        <div><s>{this.state.motErreur}</s></div><p>{this.dataJeu.info[this.noQuestion].f}</p><div>
                            {this.state.motErreur && <Button type="primary" className="margeHaut" onClick={this.suivant}>Suivant</Button>}</div></div> :
                        <div className="saisieVocal"><p className="margeDroite">Écrire la phrase en anglais : </p>
                   
                            <div><Saisie ref={this.child} change={this.change}></Saisie></div>
                            <div className='espaceInfoLettre margeHaut30'>
                            <Lettres type="phrase" tabLettres={this.state.tabLettresReponse}></Lettres></div>
                            <div className='centre moyenneFont'>{this.state.traduction}</div>
                            <div className="centre margeHaut30"><Button type='primary' onClick={this.clicPasser}>Je passe</Button></div>
                            </div>}
                </React.Fragment>

            </div></React.Fragment> :
            (this.data.concours ? <FinEtape nom={this.nom} donneesJeu={this.data.jeu} nbFautes={this.stat.resultat.nbFautes}></FinEtape> : <Resultat nom={this.nom} id={this.id} statistique={this.stat} donnees={this.data}></Resultat>)}
    </div>

}
}
