import DataAccess from '../../data/DataAccess';
import env from '../../components/commun/config';

export default class LogiquePhraseTrou
{
    constructor(id)
    {
       
        this.data = new DataAccess(env.nomPhraseTrou, id);
        this.texte = this.data.jeu.texte;
        this.extrait = this.data.jeu.extrait;
        this.titre = this.data.jeu.titre;
        this.audio = this.data.jeu.audio;
        
    }

    verifierArrobase(mot)
    {
        return mot.startsWith('@');
    }
    
    SupprimerArrobase(mot)
    {
        return mot.substring(1, mot.length);
    }

    ConstruireTableauPhrase()
    {
        return this.texte.split(' ');
    }

    CalculNombreReponse()
    {
        let reg = /@/g;
        return this.texte.match(reg).length;
    }


}
