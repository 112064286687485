import React from 'react';
import DataPhrase from '../data/dataPhrase';
import { Row, Col } from 'antd';
import {Helmet } from 'react-helmet';


export default function Phrase2() {

 
    function construction(jeu,i) {
        return <div key={i}><h2 className="couleurLien">{jeu.titreF}</h2>
        <div>{jeu.info.map((mots, i) => <div key={i} className="motsVocaEspace">{mots.f} : <div className="motsVoca">{mots.a} {mots.indicA !== undefined && <div className="couleurIndice">{mots.indicA}</div>} </div></div>)}
        </div></div>
    }

    return <div> 
           <Helmet>
          <title>Liste de phrases français vers anglais</title>
          <meta name="description" content="Liste de phrases courtes pour apprendre l'anglais avec des tests."/>
            </Helmet>
        <h1>Liste de phrases en anglais avec traduction française</h1>
        <p>Vous pouvez retrouver sur cette page tous les mots qui sont contenus dans <a href="https://anglais.evalquiz.com/test-vocabulaire-anglais/phrase/francais/1">les tests de traduction de phrases courtes</a> de ce site. Si vous voulez obtenir un PDF de cette liste  il suffit d'imprimer la page puis de choisir "Save as PDF" dans le choix de l'imprimante.</p>
        <p>Si vous avez des questions, des améliorations à proposer, vous pouvez consulter notre <a href="https://evalquiz.com/home/faq">FAQ</a> ou nous envoyer un message par <a href="https://twitter.com/evalquiz">Twitter</a>.</p>
        <Row  className="margeHaut">
        {DataPhrase.map((jeu, i) =>   
    <Col className="espaceCol" md={12}>{construction(jeu, i)}
    </Col>)}</Row>

    </div>
}