import SousMenu from './SousMenu';
import React, { Component } from 'react';
import { Row, Col, Card } from 'antd';
import env from '../commun/config.js';
import border from '../../images/border.png';
import Liens from './Liens';
import { Helmet } from 'react-helmet';
import Ad from '../commun/AdSense';
import { Link } from 'react-router-dom';
import SousMenuVoca from './SousMenuVoca.js';
import ClassementVocabulaire from '../commun/ClassementVocabulaire.js';




import { readListErrors } from '../commun/localStorage'


export default class VocabulaireTest extends Component {


    constructor() {
        super();
      
    }

    choixMessageListe = (nom, mot, langue) => {
        let resultat = readListErrors(nom);
        if (resultat.info.length === 0) {
            return <div>{"Tu n'as pas encore de " + mot + " à revoir."}</div>

        }
        else {


            let motLangue = resultat.info.map(mot => langue === 'f' ? mot.f : mot.a);
            return <div>{motLangue.map((mot, i) => <span key={i + langue} className="margeDroite">{mot.length > 12 ? mot.substring(0, 12) + '... ' : mot + ' '}</span>)}<div className="grandeFont centre"><Link to={nom + '/9999'}>Revoir ces mots</Link></div></div>

        }

    }

    render() {
        return <div>
             <Helmet>
          <title>Tests et quiz de vocabulaire pour apprendre l'anglais</title>
          <meta name="description" content="Le vocabulaire anglais par thème pour réviser ou apprendre de nouveaux mots en anglais. Vous pouvez trouver des mots de vocabulaire pour tous les niveaux (débutant ou avancé)."/>
                 </Helmet>
            <h1 className="titre centre">Vocabulaire français vers anglais par thème</h1>
            <div className="centre"><img src={border} alt="bordure"></img></div>
            <p>Bienvenue sur notre page dédiée à l'apprentissage du vocabulaire anglais.</p>

<p>Maîtriser une nouvelle langue peut être un défi, mais avec les bons outils et ressources, cela devient une aventure enrichissante. Ici, nous te proposons une variété de techniques et d'exercices pour améliorer ton vocabulaire anglais de manière efficace et ludique.

Que tu sois débutant ou que tu souhaites perfectionner ton anglais, tu trouveras </p>
<ul>
<li>Des listes de mots thématiques classées par thèmes (voyages, affaires, cuisine, etc.) pour enrichir ton vocabulaire anglais.</li>
<li>Du vocabulaire pour de l'anglais professionnel utilisé dans l'entreprise et le monde du travail (ressources humaines, entretien d'embauche...)</li>
<li>Des exercices interactifs ludiques pour tester tes connaissances et apprendre de nouveaux mots grâce à nos quiz. Apprendre le vocabulaire anglais devient amusant avec notre système d'aide et de suggestion.</li>
</ul>

            
       
            <Row className="margeHaut">
                <Col md={24}>
                    <h2>Vocabulaire : tester son vocabulaire français vers anglais</h2>
                    <p>Tu peux tester tes connaissances en vocabulaire dans ces tests. Tu peux choisir de faire le test avec le mot en français ou en anglais.</p>
                    <p>Les tests contiennent toujours 10 mots à traduire.</p>
                </Col>
            </Row>
            <p>Si tu  veux faire les tests dans l'autre sens, tu peux aller sur notre page <Link to='/test-vocabulaire-english'>vocabulaire anglais vers français</Link>. Les tests sont plus difficiles dans ce sens.</p>
            <Row gutter={{ md: 16 }}>
                <Col className="margeHaut" md={24}>
               <p>Lexique de tous les mots de<Link to='vocabulaire-francais'> vocabulaire <b>français vers anglais</b> </Link>de cette page</p>
                    <SousMenuVoca type='vocabulaire' langue='francais' nom={env.obtenirNomComplet('francais', 'vocabulaire')} titre="Vocabulaire français->anglais"></SousMenuVoca>
                    <Card className="margeHaut20 fondCard">
                        
                        <div>Liste des mots que tu ne maitrises pas encore :</div>
                        {this.choixMessageListe(env.obtenirNomComplet('francais', 'vocabulaire'), 'mots', 'f')}
                    </Card>
                </Col>
              

            </Row>
           <p>Nos tests regroupent plus de 500 mots de vocabulaire anglais. Pour avoir un niveau moyen en anglais, souvent considéré comme un niveau intermédiaire (B1/B2 sur l'échelle CECRL), il est généralement recommandé de connaître entre 2 000 et 3 000 mots. </p>
            <ClassementVocabulaire type='2'nom={env.obtenirNomComplet('francais', 'vocabulaire')} ></ClassementVocabulaire>

   
        
            <Liens></Liens>
        </div>
    }
}