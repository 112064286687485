import React, { Component } from 'react';
import { Progress } from 'antd';

export default class ProgressBar extends Component {

    constructor() {
        super();
        this._intervalId = 0;
        this.state = {
            percentProgress : 0
        }
    }

    componentDidMount() {
        this._intervalId = setInterval(() => {
            this.setTimePassed();
        }, this.props.timeInterval);
    };

    componentWillUnmount() {
        clearInterval(this._intervalId);
      }

    setTimePassed = (e) => {    
        if (this.state.percentProgress > 99.7 )
        {   
            clearInterval(this._intervalId);
           this.props.onEndProgress();
        
        }
        else
        {
            this.setState({ percentProgress: this.state.percentProgress + .4 })
        }
        
    }
    
   
    render() {
        return (
            <div>
                <Progress  percent={this.state.percentProgress} showInfo={false} status="exception" />
            </div>

        );
    }
}