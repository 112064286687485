import React, { Component } from 'react';

export default class Lettres extends Component {




    render() {
        return <div className='margeBas10 lettres500'>{this.props.tabLettres.map(lettreInfo => <span className={(this.props.type === "vocabulaire" ? 'bordLettreMot ' : 'bordLettrePhrase ') + lettreInfo.couleurLettre} id={lettreInfo.id} key={lettreInfo.id}>{lettreInfo.lettreEnCours}</span>)}</div>
    }
}