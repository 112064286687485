import React, { Component } from 'react';


class VirtualKeyboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: ''
    };
  }

  handleClick = (value) => {
    let newInput = this.state.input + value;
    this.setState({
      input: newInput
    });
    if (this.props.verifierReponse(newInput))
    {
   
        window.setTimeout(() => this.handleClear(), 1300)
    }
  }

  handleBackspace = () => {
    this.setState((prevState) => ({
      input: prevState.input.slice(0, -1)
    }));
  }

  handleClear = () => {
    this.setState({
      input: ''
    });
  }

  renderButton = (value) => {
    return (
      <button className="buttonKey" onClick={() => this.handleClick(value)} key={value}>
        {value}
      </button>
    );
  }

  render() {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    const numbers = '0123456789'.split('');
    const punctuation = '!-;:,.<>?/%='.split('');

    return (
        <div className='bodyKey'>
      <div className="virtual-keyboard">
        <div className="input-container">
          <input  type="text" value={this.state.input} readOnly />
        </div>
        <div className="buttons-container">
          {letters.map(this.renderButton)}
        </div>
        <div className="buttons-container">
          {numbers.map(this.renderButton)}
        </div>
        <div className="buttons-container">
          {punctuation.map(this.renderButton)}
        </div>
        <div className="special-buttons">
          <button className="buttonKey" onClick={this.handleBackspace}>🠔</button>
          <button className="buttonKey" onClick={this.handleClear}>🗑️</button>
        </div>
      </div></div>
    );
  }
}

export default VirtualKeyboard;
