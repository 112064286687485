import React, { Component } from 'react';

export default class Audio extends Component {

    constructor(props) {
        super();
        this.audio = React.createRef();
        this.fichierEnCours = 0;
        this.state =
        {
            etat: 'bouton1PlayCode'
        }
    }
    componentDidMount() {


        // let promise = this.audio.current.play();

        // if (promise !== undefined) {
        //     promise.catch(error => {
        //         console.log("pas démarré");
        //         this.setState({ etat: 'boutonPlayCode pausedCode' });
        //     });
        // };
    }

    finAudio = () => {
       
            this.setState({  etat: 'bouton1PlayCode' });

        

    }
    demarreAudio = () => {
      
            if (this.audio.current.paused) {
                this.audio.current.play();
            }
     

    }

    clic = () => {
        this.audio.current.currentTime = 0;
            this.audio.current.play();
            
     
        
this.setState({ etat: 'bouton1PlayCode' });
    }
    render() {
        return <div><button   className={this.state.etat} onClick={this.clic} ></button><audio 
   
            ref={this.audio}
            src={this.props.src}
            onEnded={this.finAudio}>
        </audio>
        </div>

    }
}