import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tag, Card, Row, Col } from 'antd';
import { readVocaValide } from '../../components/commun/localStorage';
import DataAccess from '../../data/DataAccess';

import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
  } from '@ant-design/icons';


export default class SousMenuVoca extends Component {

    constructor(props) {
        super();
        let donnees = DataAccess.donneesSuivantType(props.nom)
        this.nom = props.nom;
        this.listeValide = readVocaValide(props.type, props.langue);

        this.state = { donnees : donnees.sort((a, b) => a.niveau - b.niveau) }

    }
    ajouterTag(id) {
     
     
            let jeu = this.listeValide.find(x => x.id === id);
            let msg = '';
            let color = '';
            if (jeu !== undefined) {
                if (jeu.etat === 'valide') {
                 
                    return <Tag icon={<CheckCircleOutlined />} color="success">
                    Validé
                  </Tag>
                }
                else {
                   return <Tag icon={<CloseCircleOutlined />} color="error">
        À refaire
      </Tag>
                }
            }
            else {
             return   <Tag icon={<ClockCircleOutlined />} color="default">
                En attente
              </Tag>
            }
           
        
    }



render() {

    return (<div> <Card className={this.props.className} title={<span style={{ whiteSpace: 'normal' }}>{this.props.titre}</span>}>

        {this.state.donnees.map((v, i) => <Row gutter={{ md: 16, xs:8 }} key={i}><Col md={6} xs={12}>{this.props.supprimer !== v.id ?  <Link to={'/' + this.nom + '/' + v.id}>{v.titre}</Link>:
        <b><Link to={'/' + this.nom + '/' + v.id}>{v.titre}</Link></b>}</Col>
            {v.hasOwnProperty('niveau') && v.niveau !== undefined && <Col md={3} xs={4} className="petiteLettre">{DataAccess.transformeNiveau(v.niveau)}</Col>}
            <Col md={3} xs={2} className="margeGauche">{this.ajouterTag(v.id)}</Col></Row>)}

    </Card></div>)

}
}

SousMenuVoca.defaultProps = {
    supprimer: 'rien',
};



