export default class Statistique {
    constructor(nbQuestions) {
        this.resultat = {
            nbFautes: 0,
            nbQuestions: nbQuestions,
            listeFautes: []
        }
    }


    ajouterMot(mot) {
        if (this.resultat.listeFautes.indexOf(mot) === -1) {
            this.resultat.listeFautes.push(mot);
        }
    }
    ajouterFaute() {
        this.resultat.nbFautes++;
    }

    ajouter2Fautes() {
        this.resultat.nbFautes+=2;
    }
    ajouterMotEtFaute(mot) {
        this.ajouterMot(mot)
        this.ajouterFaute()
    }

    calculerNote() {
        let note = (Math.round(((this.resultat.nbQuestions - this.resultat.nbFautes) / this.resultat.nbQuestions) * 100) * 20) /100;
        return note >= 0 ? note : 0;
    
    }
}