
import SousMenu from './SousMenu';
import React, { Component } from 'react';
import { Row, Col, Card } from 'antd';
import env from '../commun/config.js';
import border from '../../images/border.png';

import { Helmet } from 'react-helmet';
import Ad from '../commun/AdSense';
import { Link } from 'react-router-dom';
import { readListErrors } from '../commun/localStorage';
import Liens from './Liens';


export default class GrammaireTest extends Component {

    constructor()
    {
        super();

    }
    choixMessageListe = (nom, mot, langue) => {
        let resultat = readListErrors(nom);
        if (resultat.info.length === 0) {
            return <div>{"Vous n'avez pas encore de " + mot + " à revoir."}</div>

        }
        else {

            let motLangue = resultat.info.map(mot => langue === 'f' ? mot.f : mot.a);
            return <div>{motLangue.map((mot, i) => <span key={i + langue} className="margeDroite">{mot.length > 12 ? mot.substring(0, 12) + '...' : mot}</span>)}<div className="grandeFont centre"><Link to={nom + '/9999'}>Revoir ces phrases</Link></div></div>

        }

    }
    render() {
        return <div>
                    <Helmet>
          <title>Quiz et tests de grammaire en anglais</title>
          <meta name="description" content="Des tests et des petits quiz amusants pour travailler la langue anglaise."/>
                 </Helmet>
            <div className="centre"><img src={border} alt="bordure"></img></div>
            <h1 className="titre centre">Quiz et grammaire anglaise</h1>

            <Row gutter={{ md: 16 }} className="margeHaut">

                <Col md={12}>
                    <h2>Quiz de grammaire anglaise</h2>
                    <p>Dans ce test vous devez choisir une proposition parmi plusieurs. Ce jeu reprend toutes les bases de la grammaire anglaise.</p>
                    <p>Les exemples s'inspirent des exercices des sites <a href="https://multigram.ulb.ac.be/eng/Accueil">Multigram</a> et <a href="https://www.englishexercises.org/">Englishexercises.org</a>.</p>

                </Col>
                <Col md={12}>
                    <SousMenu nom={env.nomQcm} titre="Grammaire anglaise"></SousMenu>
                </Col>
            </Row>
            <div className="centre"><img src={border} alt="bordure"></img></div>
            <Row gutter={{ md: 16 }} className="margeHaut20">

<Col md={12}>
    <h2>Conjugaison anglaise</h2>
    <p>Le classique exercice de conjugaison anglaise, il faut bien passer par là, vous devez conjuguer 5 verbes à la forme affirmative, interrogative ou négative.
        Si vous voulez revoir les temps des verbes anglais: vous pouvez aller sur ce <a href="https://www.theconjugator.com/" target="_blank" rel="noopener noreferrer">site</a>.
    </p>

</Col>
<Col md={12}>
    <SousMenu nom={env.nomConjugaison} titre="Conjugaison anglaise"></SousMenu>
</Col>
</Row>
<Row gutter={{ md: 16 }} className="margeHaut20">
                <Col md={12}>
                    <h2>Quiz de vocabulaire anglais</h2>
                    <p>Un jeu simple pour apprendre du nouveau vocabulaire en anglais.</p>

                </Col>
                <Col md={12}>
                    <SousMenu nom={env.nomListe} titre="Pyramide de mots anglais"></SousMenu>
                </Col>
            </Row>

            <Row gutter={{ md: 16 }} className="margeHaut">

<Col md={12}>
    <h2>Comprendre un texte ou une image</h2>
    <p>Dans ces quiz vous devez lire quelques phrases, écouter un texte ou regarder une image puis répondre à des questions simples pour déterminer si vous avez bien compris ce que vous avez vu ou entendu. </p>

</Col>
<Col md={12}>
    <SousMenu nom={env.nomMemoire} titre="Compréhension anglais"></SousMenu>
</Col>
</Row>


            <div className="centre"><img src={border} alt="bordure"></img></div>
            <Liens></Liens>
<Ad></Ad>           
        </div>
    }
}