import React, { Component } from 'react';
import {Input} from 'antd';



export default class Saisie extends Component {

    constructor(props) {
        super();
        this.state = {mot:""};
        this.textInput = React.createRef();
    }

    handleChange = (event) => {
            this.setState({mot : event.target.value});
            this.props.change(event.target.value);
        
    }

    setFocus = () => {
        this.textInput.current.focus();
    }

    reset = () =>
    {
        this.setState({mot : ""});
    }

    componentDidMount() {
        this.textInput.current.focus();
    }


    render() {
       
        return (<div className="margeBas10">{this.props.verbe && <span>to </span>}<Input className="saisie" type="text" autoComplete="off" autoCorrect="off" autoCapitalize="off"
        allowClear={true}    spellCheck="false" value={this.state.mot} ref={this.textInput}  onChange={this.handleChange}></Input>
   </div>)
    }
}

