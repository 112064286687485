import React, { Component } from 'react';
import { Button, Input} from 'antd';

let typeAction =
{
    rien : 0,
    focus : 1,
    reset : 2,
    focusReset : 3
}

export default class Saisie extends Component {

    constructor(props) {
        super();
        this.state = { mot: '' };
        this.textInput = React.createRef();
    }

    handleChange = (event) => {
   
            this.props.change(event.target.value);
        
    }

    clickBouton = () => {
        let action = this.props.verifierReponse();
        this.textInput.current.focus();
        if ((action & typeAction.focus) === typeAction.focus) this.textInput.current.focus();
    }

    

    componentDidMount() {
        this.textInput.current.focus();
    }


    render() {
       
        return (<div><Input className="saisie" type="text" autoComplete="off" autoCorrect="off" autoCapitalize="off"
        allowClear={true}    spellCheck="false" value={this.props.mot} ref={this.textInput} onPressEnter={this.clickBouton} onChange={this.handleChange}></Input>
        <Button className="margeGauche" type="primary" onClick={this.clickBouton}>OK</Button></div>)
    }
}

export {typeAction};