import React, { Component } from 'react';
import {Button} from 'antd';

export default class QuestionFerme extends Component
{
    constructor(props)
    {
        super(props);
        this.state = 
        {
            finReponse : false,
            resultat : false
        }
    }

    reponseVrai = () =>
    {
        this.finQuestion(true);
    }

    reponseFaux = () =>
    {
        this.finQuestion(false);
    }

    finQuestion = (reponse) =>
    {
        const resultat = this.props.test.reponse === reponse;
        this.setState({finReponse : true,
        resultat});
        this.props.resultatQuestion(this.props.noQuestion, resultat);

    }

    render()
    {
    return <div className="questionsMem margeHaut20"><span>{(this.props.noQuestion+1) + '. ' + this.props.test.question}</span>{!this.state.finReponse ? <div className="margeHaut10"><Button className="espaceGaucheDroite" type="primary" onClick={this.reponseVrai}>True</Button><Button type="danger" className="btn-danger espaceGaucheDroite"  onClick={this.reponseFaux}>False</Button></div>: 
    this.state.resultat ? <div className="couleurOK" ><b>That's right!</b></div>: <div className="couleurKO"><b>Incorrect</b></div>}</div>
    }
}