let dataCodeLettre=[
    {
        fichier : 'a',
        code : 'a',
    }
    ,
    {
        fichier : 'b',
        code : 'b',
    },
    {
        fichier : 'c',
        code : 'c',
    },
    {
        fichier : 'd',
        code : 'd',
    },
    {
        fichier : 'e',
        code : 'e',
    },
    {
        fichier : 'f',
        code : 'f',
    },
    {
        fichier : 'g',
        code : 'g',
    },
    {
        fichier : 'h',
        code : 'h',
    },
    {
        fichier : 'i',
        code : 'i',
    },
    {
        fichier : 'j',
        code : 'j',
    },
    {
        fichier : 'k',
        code : 'k',
    },
    {
        fichier : 'l',
        code : 'l',
    },
    {
        fichier : 'm',
        code : 'm',
    },
    {
        fichier : 'n',
        code : 'n',
    },
    {
        fichier : 'o',
        code : 'o',
    },
    {
        fichier : 'p',
        code : 'p',
    },
    {
        fichier : 'q',
        code : 'q',
    },
    {
        fichier : 'r',
        code : 'r',
    },
    {
        fichier : 's',
        code : 's',
    },
    {
        fichier : 't',
        code : 't',
    },
    {
        fichier : 'u',
        code : 'u',
    },
    {
        fichier : 'v',
        code : 'v',
    },
    {
        fichier : 'w',
        code : 'w',
    },
    {
        fichier : 'x',
        code : 'x',
    },
    {
        fichier : 'y',
        code : 'y',
    },
    {
        fichier : 'z',
        code : 'z',
    },
]
let dataCodeNombre=[
    { 
        fichier : 'n11',
        code : '11',
    },
    {
        fichier : 'n12',
        code : '12',
    },
    {
        fichier : 'n13',
        code : '13',
    },
    {
        fichier : 'n14',
        code : '14',
    },
    {
        fichier : 'n15',
        code : '15',
    },
    {
        fichier : 'n16',
        code : '16',
    },
    {
        fichier : 'n17',
        code : '17',

    },
    {
        fichier : 'n18',
        code : '18',
    },
    {
        fichier : 'n19',
        code : '19',
    },
    {
        fichier : 'n20',
        code : '20',
    },
    {
        fichier : 'n21',
        code : '21',
    },
    {
        fichier : 'n22',
        code : '22',
    },
    {
        fichier : 'n23',
        code : '23',
    },
    {
        fichier : 'n31',
        code : '31',
    },
    {
        fichier : 'n33',
        code : '33',
    },
    {
        fichier : 'n37',
        code : '37',
    },
    {
        fichier : 'n42',
        code : '42',
    },
    {
        fichier : 'n46',
        code : '46',
    },
    {
        fichier : 'n49',
        code : '49',
    },
    {
        fichier : 'n53',
        code : '53',
    },
    {
        fichier : 'n55',
        code : '55',
    },
    {
        fichier : 'n57',
        code : '57',
    },
    {
        fichier : 'n62',
        code : '62',
    },
    {
        fichier : 'n63',
        code : '63',
    },
    {
        fichier : 'n68',
        code : '68',
    },
    {
        fichier : 'n71',
        code : '71',
    },
    {
        fichier : 'n76',
        code : '76',
    },
    {
        fichier : 'n79',
        code : '79',
    },
    {
        fichier : 'n83',
        code : '83',
    },
    {
        fichier : 'n85',
        code : '85',
    },
    {
        fichier : 'n88',
        code : '88',
    },
    {
        fichier : 'n91',
        code : '91',
    },
    {
        fichier : 'n94',
        code : '94',
    },
    {
        fichier : 'n97',
        code : '97',
    },

]
let dataCodeSigne=[
    {
        fichier : 's1',
        code : '.',
    },
    {
        fichier : 's2',
        code : ',',
    },
    {
        fichier : 's3',
        code : ';',
    },
    {
        fichier : 's4',
        code : ':',
    },
    {
        fichier : 's5',
        code : '!',
    },
    {
        fichier : 's6',
        code : '?',
    },
    {
        fichier : 's7',
        code : '>',
    },
    {
        fichier : 's8',
        code : '<',
    },
    {
        fichier : 's9',
        code : '/',
    },
    {
        fichier : 's10',
        code : '-',
    },
    {
        fichier : 's11',
        code : '%',
    },
    {
        fichier : 's12',
        code : '=',
    }
]
export {dataCodeLettre,dataCodeNombre, dataCodeSigne};