import React from 'react';

function Mots4(props)
{
    return <div className="margeHaut30">{props.tabMots.map((mot,i) => <span key={i} className="mot4">{mot}</span>)}</div>
}

function MotMelange(props)
{
        return <div className='margeHaut30 lettres500'>{props.tabLettres.map((lettre,i) => <span className="bordAide4"  key={i}>{lettre}</span>)}</div>
}

export {Mots4, MotMelange}