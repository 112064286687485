import SousMenu from './SousMenu';
import React, { Component } from 'react';
import { Row, Col, Card } from 'antd';
import env from '../commun/config.js';
import border from '../../images/border.png';
import Liens from './Liens';
import { Helmet } from 'react-helmet';
import Ad from '../commun/AdSense';
import { Link } from 'react-router-dom';
import SousMenuVoca from './SousMenuVoca.js';
import ClassementVocabulaire from '../commun/ClassementVocabulaire.js';




import { readListErrors } from '../commun/localStorage'


export default class VocabulaireTestAnglais extends Component {


    constructor() {
        super();
      
    }

    choixMessageListe = (nom, mot, langue) => {
        let resultat = readListErrors(nom);
        if (resultat.info.length === 0) {
            return <div>{"Tu n'as pas encore de " + mot + " à revoir."}</div>

        }
        else {


            let motLangue = resultat.info.map(mot => langue === 'f' ? mot.f : mot.a);
            return <div>{motLangue.map((mot, i) => <span key={i + langue} className="margeDroite">{mot.length > 12 ? mot.substring(0, 12) + '... ' : mot + ' '}</span>)}<div className="grandeFont centre"><Link to={nom + '/9999'}>Revoir ces mots</Link></div></div>

        }

    }

    render() {
        return <div>
             <Helmet>
          <title>Tests de vocabulaire anglais classés par thème</title>
          <meta name="description" content="Le vocabulaire anglais par thème pour réviser ou apprendre de nouveaux mots en anglais. Vous pouvez trouver des mots de vocabulaire pour tous les niveaux (débutant ou plus avancé)."/>
                 </Helmet>
            <h1 className="titre centre">Vocabulaire anglais vers français par thème</h1>
           
            
       
            <Row className="margeHaut">
                <Col md={24}>
                    <h2>Vocabulaire : tester son vocabulaire anglais vers français</h2>
                    <p>Tu peux tester tes connaissances en vocabulaire dans ces tests. Tu peux choisir de faire le test avec le mot en français ou en anglais.</p>
                    <p>Les tests contiennent toujours 10 mots à traduire.</p>
                </Col>
            </Row>
            <p>Si tu veux faire les tests dans l'autre sens, tu peux aller sur notre page <Link to='/test-vocabulaire'>vocabulaire français vers anglais</Link>.  Les tests sont plus difficiles dans ce sens.</p>

            <Row gutter={{ md: 16 }}>
                <Col className="margeHaut" md={24}>
                <p>Lexique de tous les mots de <Link to='vocabulaire-anglais'>vocabulaire <b>anglais vers français</b> </Link>de cette page</p>
                    <SousMenuVoca type='vocabulaire' langue='anglais' nom={env.obtenirNomComplet('anglais', 'vocabulaire')} titre="Vocabulaire anglais->français"></SousMenuVoca>
                    <Card className="margeHaut20 fondCard">
                        
                        <div>Liste des mots que tu ne maitrises pas encore</div>
                        {this.choixMessageListe(env.obtenirNomComplet('anglais', 'vocabulaire'), 'mots', 'f')}
                    </Card>
                </Col>
              

            </Row>
          
            <ClassementVocabulaire type='1'nom={env.obtenirNomComplet('anglais', 'vocabulaire')} ></ClassementVocabulaire>

           
            <Liens></Liens>
        </div>
    }
}