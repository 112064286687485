import donneesJeuxConcours from '../../data/dataJeuxConcours';

function prochainObjectif(id) {
    const jeu = donneesJeuxConcours.find(x => x.id === id);
    if (jeu != null) {
        if (jeu.qualifFaute !== undefined) return jeu.qualifFaute === 0 ? 'Vous devez réaliser 0 faute.' : 'Vous devez faire moins de ' + (jeu.qualifFaute + 1) + ' fautes.'
        if (jeu.qualifTemps !== undefined) return 'Vous devez réussir le test en moins de ' + jeu.qualifTemps + ' secondes.';
        if (jeu.qualifScore !== undefined) return 'Vous devez réussir le test avec un score supérieur ou égal à ' + jeu.qualifScore + '.';
    }
    return "Réussir le test";
}

function messageErreur(id) {
    const jeu = donneesJeuxConcours.find(x => x.id === id);
    if (jeu != null) {
        if (jeu.qualifFaute !== undefined) return jeu.qualifFaute === 0 ? 'Vous deviez réaliser un sans-faute pour vous qualifier.' : "Vous n'avez pas réussi à faire moins de " + (jeu.qualifFaute + 1) + ' fautes pour vous qualifier.'
        if (jeu.qualifTemps !== undefined) return 'Vous deviez réussir le test en moins de ' + jeu.qualifTemps + ' secondes pour vous qualifier.';
        if (jeu.qualifScore !== undefined) return 'Vous deviez obtenir un score supérieur ou égal à ' + jeu.qualifScore + ' pour vous qualifier.';
      
    }
    return '';
}

export { prochainObjectif, messageErreur }