import {dataCodeLettre, dataCodeNombre, dataCodeSigne} from './dataCode';

export default class Logique
{
    constructor()
    {
        this._tabCode= [];
        this._fichiers = [];
        this._codes='';
    }
    get fichiers()
    {
        return this._fichiers;
    }
    get codes()
    {
        return this._codes;
    }

    set fichiers(fichiers)
    {
        this._fichiers = fichiers;
    }
    set codes(codes)
    {
        this._codes = codes;
    }

    construireCodes(niveau)
    {
        this._tabCode = [];
        this._fichiers = [];
        this._codes = '';
        if (niveau === 0)
        {
            for (let index = 0; index < 3; index++) {
                this.construireCodeLettre();  
            }
        }
        else if (niveau === 1)
        {
            this.construireCodeLettre();
            this.construireCodeNombre();
            this.construireCodeLettre();
        }
        else if (niveau === 2)
        {
            this.construireCodeLettre();
            this.construireCodeNombre();
            this.construireCodeSigne();
        }
       
       this.construireFichiers();
        this.construireCode();
       
    }

    construireCodeLettre()
    {
             let index = 0;
           do
            {
                index = Math.floor(Math.random() * dataCodeLettre.length);
            } while (this._tabCode.findIndex(x => x.code === dataCodeLettre[index].code) !== -1)
      
         this._tabCode.push(dataCodeLettre[index]);
       
    }

    construireCodeNombre()
    {
        let index = 0;
        index = Math.floor(Math.random() * dataCodeNombre.length);
      this._tabCode.push(dataCodeNombre[index]);
    }

    construireCodeSigne()
    {
        let index = 0;
        index = Math.floor(Math.random() * dataCodeSigne.length);
      this._tabCode.push(dataCodeSigne[index]);
    }

    construireFichiers(){
    
        this._tabCode.forEach(element => {
            this._fichiers.push('/audio/code/'+ element.fichier + '.mp3');
        });
       
    }
    construireCode(){
        
        this._tabCode.forEach(element => {
            this._codes+=element.code;
        });
        
    }
}