import React, { Component } from 'react';
import Audio from '../commun/Audio';

import Saisie, { typeAction } from '../commun/Saisie';
import { transformSentence } from '../commun/logiqueTraduction';
import DataAccess from '../../data/DataAccess';
import { Helmet } from 'react-helmet';
import { Button, message } from 'antd';
import { Compteur } from '../commun/Aide';
import ProgressBar from '../commun/ProgressBar';
import Resultat from '../../components/commun/Resultat';
import Statistique from '../../components/commun/Statistique';
import { Redirect } from 'react-router-dom';
import env from '../../components/commun/config';
import { addGame } from '../../components/commun/localStorage';

import FinEtape from '../concours/FinEtape';


export default class JeuRapide extends Component {

    constructor(props) {
        super();
        this.id = parseInt(props.match.params.id);
        this.stop = false;
        this.data = new DataAccess(env.nomRapide, this.id);

        this.dataJeu = this.data.obtenirDonneesJeuMelange();
        if (this.dataJeu == null) {
            this.stop = true;
        }
        else {
            this.noQuestion = 0;
            this.nbQuestion = this.dataJeu.info.length;
            this.timer = null;
            this.state = {
                nomFichier: '/audio/' + this.dataJeu.info[this.noQuestion].audio,
                mot: '',
                reponseEnCours: false,
                afficheResultat: false,
                bouton: true
            }
            this.reponseEnCours = false;
            this.temps = this.dataJeu.temps;
            this.type = this.dataJeu.type;
            this.stat = new Statistique(this.nbQuestion);
            this.nom = env.nomRapide;
            addGame(this.nom, this.id);
       

        }
    }


    echec = (msg) => {
        if (msg == null) msg = 'Temps dépassé';
        message.error(msg, 2.5, this.restart);
        this.setState({
            reponseEnCours: true
        })
        this.stat.ajouterMotEtFaute(this.dataJeu.info[this.noQuestion].a);
    }

    restart = () => {
        this.noQuestion++;
        if (this.noQuestion === this.nbQuestion) {
            this.setState({
                afficheResultat: true
            })
        }
        else {
            this.setState({
                nomFichier: '/audio/' + this.dataJeu.info[this.noQuestion].audio,
                mot: '',
                reponseEnCours: false
            })
        }

    }

    verifierReponse = () => {
        if (!this.state.reponseEnCours) {
            this.setState({
                reponseEnCours: true
            })
            if (transformSentence(this.state.mot) === transformSentence(this.dataJeu.info[this.noQuestion].a)) {

                message.success("Well done!", 1, this.restart)
            }
            else {
                this.echec("Error, correct answer : " + this.dataJeu.info[this.noQuestion].a);
            }
        }
        return typeAction.focus;
    }

    change = (phrase) => {
        this.setState({
            mot: phrase
        });
    }

    click = () => {
        this.setState({
            bouton: false
        });
    }

    formatAttendu = () => {
        switch (this.type) {
            case 'motLettre':
                return <div>Écrivez simplement les mots épelés sans espace exemple : boat ou lake</div> 
            case 'lettre':
                return <div>Écrivez les lettres que vous allez entendre par exemple : a ou p</div>
            case 'nombre':
                return <div>Écrivez les nombres que vous allez entendre par exemple : 66 ou 567</div>
            case 'nombreVirgule':
                return <div>Écrivez les nombres à virgule que vous allez entendre par exemple : 66.6 ou 567.4. Utilisez le point anglais.</div>
            case 'date':
                return <div>Écrivez les dates que vous allez entendre par exemple : 23.2.1970. Le format est celui du français : jour.mois.année.</div>
            case 'heure':
                return <div>Écrivez les heures que vous allez entendre dans le format hh.mm par exemple : 23.40 ou 15.00.</div>
                case 'nom':
                    return <div>Écrivez les noms des personnes qui sont épelés lettre par lettre par exemple : redford ou eastwood. Pas besoin de majuscule. Bonne chance !</div>
           
            case 'mot':
                return <div>Écrivez simplement les mots que vous entendez.</div>

            default:
                return ''
        }
    }
    render() {
        if (this.stop) {
            return (<Redirect to='/'></Redirect>);
        }
        return <React.Fragment><Helmet>
            <title>Test d'anglais : comprendre les mots anglais prononcés</title>
            <meta name="description" content="Exercices de compréhesion orale d'anglais gratuits avec la solution." />
        </Helmet>{this.state.bouton ? <div className="centre">
            <h2>{this.dataJeu.titre}</h2>
            <Button size="large" type="primary" onClick={this.click}>Je suis prêt</Button>
            <h3>Jeu de rapidité : écrivez ce que vous entendez en anglais sans le traduire.</h3>
            {this.formatAttendu()}
    
        </div> : !this.state.afficheResultat ? <div><h1>{'Test de rapidité anglais : ' + this.dataJeu.titre}</h1>
            <p>Ecrivez ce que vous entendez en anglais</p>
            <div><Compteur noQuestion={this.noQuestion + 1} nbQuestion={this.nbQuestion}></Compteur></div>
            <div className="flexVocal"><div className="audioVocal">{!this.state.reponseEnCours && <Audio depart={true} nomFichier={this.state.nomFichier}></Audio>}</div>
                <div className="saisieVocal"><Saisie change={this.change} mot={this.state.mot} verifierReponse={this.verifierReponse}></Saisie></div>
                {!this.state.reponseEnCours && <ProgressBar timeInterval={this.temps} onEndProgress={this.echec}></ProgressBar>}</div></div>
            : (this.data.concours ? <FinEtape nom={this.nom} donneesJeu={this.data.jeu} nbFautes={this.stat.resultat.nbFautes}></FinEtape> : <Resultat nom={this.nom} id={this.id} statistique={this.stat} donnees={this.data}></Resultat>)}
        </React.Fragment>

    }
}