import React, { Component } from 'react';


export default class Reponses extends Component
{
    clickMot = (event) =>
{
    let id =  parseInt(event.currentTarget.id);
    this.props.selectionMot(id);
}
    render()
    {
        return <div className="qcmReponse">{this.props.mots.map((mot,i) => <span className="motReponse" key={i} id={i} onClick={this.clickMot} >{mot}</span>)}</div>
    }
}