import React, { Component } from 'react';
import Lettres from './Lettres';
import DataAccess from '../../data/DataAccess';
import config from '../../components/commun/config';

import Saisie from './Saisie';
import { Compteur } from '../commun/Aide';
import { message, Button } from 'antd';
import { MotMelange } from './aide';
import { melangeLettres, supprimerTo } from './logique';
import Erreur from './Erreur';
import FinEtape from '../concours/FinEtape';
import Resultat from './Resultat';
import { Helmet } from 'react-helmet';
import { readVocaValide, addVocaValide } from '../../components/commun/localStorage';

import { addGame } from '../../components/commun/localStorage';


export default class JeuVoca500 extends Component {


    constructor(props) {
        super(props);
        this.id = parseInt(props.match.params.id);
        this.langue = props.match.params.langue;
        this.type = props.match.params.type;
        this.listeFaute = [];
        this.stop = false;
        this.nom = config.obtenirNomComplet(this.langue, this.type);
        this.data = new DataAccess(this.nom, this.id);
        this.dataJeu = this.data.obtenirDonneesJeuMelange();
        this.nbErreursFaute = this.type === "phrase" ? 8 : 4
        this.noQuestion = 0;
        this.erreursMot = 0;
        this.nbQuestion = this.dataJeu.info.length;

        this.child = React.createRef();
        this.LogiqueErreur = new Erreur(this.nom);
        this.fin = false;
        this.state = {
            tabLettresReponse: [],
            motQuestion: "",
            verbe: false,
            afficheMotMelange: false,
            afficheBoutonAideLettre: true,
            afficheBoutonAideMelange: true,
            aideLettre: 10,
            aideMotMelange: 2,
            questionEnCours: 0,
            afficheResultat: false,
            fautes: 0
        }

        addGame(this.nom, this.id);
    }

    obtenirQuestion = () => {
        return this.langue === "anglais" ?
            {
                texte: this.dataJeu.info[this.noQuestion].a,
                indic: this.dataJeu.info[this.noQuestion].indicA
            }
            :
            {
                texte: this.dataJeu.info[this.noQuestion].f,
                indic: this.dataJeu.info[this.noQuestion].indicF
            }
    }

    obtenirReponse = () => {
        return this.langue === "anglais" ? this.dataJeu.info[this.noQuestion].f : this.dataJeu.info[this.noQuestion].a;
    }

    nouveauMot = () => {
     //   if (this.noQuestion === 2) {
             if (this.noQuestion === this.dataJeu.info.length) {
            if (!this.data.concours) {
                addVocaValide(this.type, this.langue, this.id, this.listeFaute.length === 0 ? 'valide' : 'refaire');
            }
            this.LogiqueErreur.sauvegardeErreur();
            this.setState({ afficheResultat: true });
            return;
        }
        this.fin = false;
        this.erreursMot = 0;
        let motQuestion = this.obtenirQuestion();
        let mots = this.obtenirReponse();
        mots = mots.trim();
        let lastElement = mots.charAt(mots.length - 1);
        let regPunctuation = /[,;:.!?\\-]/;

        if (lastElement.match(regPunctuation)) {
            mots = mots.slice(0, mots.length - 1).trim();
        }
        let { motReponse, verbe } = supprimerTo(mots);
        let lettresReponse = motReponse.split('');
        let lettresEnCours = new Array(lettresReponse.length).fill('');
        let tabLettresReponse = lettresReponse.map((lettre, i) => { return { lettre, id: i, lettreEnCours: lettresEnCours[i], couleurLettre: lettre === ' ' ? "vide500" : "orange500" } });


        this.tabMotsMelange = melangeLettres(motReponse, this.type);
        this.setState({
            tabLettresReponse,
            motQuestion,
            verbe,
            afficheMotMelange: false,
            questionEnCours: this.noQuestion,
            fautes: 0
        })
        this.child.current.reset();
        this.child.current.setFocus();
    }

    componentDidMount = () => {
        this.nouveauMot();

    }

    change = (mot) => {
        if (this.fin) return;

        let nouveauTabLettre = [...this.state.tabLettresReponse];

        let motJoueur = mot.split('');
        let longueur = motJoueur.length >= this.state.tabLettresReponse.length ? this.state.tabLettresReponse.length : motJoueur.length;
        let nbBon = 0;
        for (let index = 0; index < longueur; index++) {
            const lettre = motJoueur[index];

            if (nouveauTabLettre[index].lettre === lettre) {
                nouveauTabLettre[index].couleurLettre = 'vert500';
                nbBon++;
            }
            else {
                if (nouveauTabLettre[index].couleurLettre !== 'rouge500') {
                    this.erreursMot++;
                }
                if (this.erreursMot > this.nbErreursFaute) {
                    this.clicPasser();
                    return;
                }
                nouveauTabLettre[index].couleurLettre = 'rouge500';


            }
            nouveauTabLettre[index].lettreEnCours = motJoueur[index];
        }
        if (nbBon === nouveauTabLettre.length && motJoueur.length === nouveauTabLettre.length) {

            this.fin = true;

            //  this.LogiqueErreur.ajouterErreur(this.dataJeu.info[this.noQuestion]);

            this.LogiqueErreur.supprimerErreur(this.dataJeu.info[this.noQuestion]);

            this.noQuestion++;
            message.success('Bravo', 1, this.nouveauMot);
        }
        else {
            for (let index = longueur; index < nouveauTabLettre.length; index++) {
                nouveauTabLettre[index].couleurLettre = nouveauTabLettre[index].lettre === ' ' ? "vide500" : "orange500";
                nouveauTabLettre[index].lettreEnCours = '';

            }
        }
        this.setState({ tabLettresReponse: nouveauTabLettre, fautes: this.erreursMot });
    }

    clicAideLettre = () => {
        if (this.fin) return;
        this.child.current.setFocus();
        let nouveauTabLettre = [...this.state.tabLettresReponse];
        let nbBon = 0;
        for (let index = 0; index < nouveauTabLettre.length; index++) {
            nbBon++;
            if (nouveauTabLettre[index].lettreEnCours !== nouveauTabLettre[index].lettre) {

                nouveauTabLettre[index].couleurLettre = 'vert500';
                nouveauTabLettre[index].lettreEnCours = nouveauTabLettre[index].lettre;
                break;
            }
        }

        if (nbBon === nouveauTabLettre.length) {
            this.noQuestion++;
            this.fin = true;
            message.success('Ok', 1, this.nouveauMot);
        }

        this.setState({
            aideLettre: this.state.aideLettre - 1,
            afficheBoutonAideLettre: !!(this.state.aideLettre - 1),
            tabLettresReponse: nouveauTabLettre,
        });
    }



    clicMotMelange = () => {
        this.setState({
            afficheMotMelange: true,
            aideMotMelange: this.state.aideMotMelange - 1,
            afficheBoutonAideMelange: !!(this.state.aideMotMelange - 1)
        });
        this.child.current.setFocus();
    }

    clicPasser = () => {
        if (this.fin) return;
        this.fin = true;
        this.LogiqueErreur.ajouterErreur(this.dataJeu.info[this.noQuestion]);
        this.listeFaute.push(this.dataJeu.info[this.noQuestion]);
        let reponse = this.obtenirReponse();
        let nouveauTabLettre = reponse.split('').map((lettre, i) => { return { lettre, id: i, lettreEnCours: lettre, couleurLettre: lettre === ' ' ? "vide500" : "orange500" } });

        this.fin = true;
        message.error("Tu n'as pas trouvé", 4, this.nouveauMot);
        this.child.current.reset();
        this.setState({ tabLettresReponse: nouveauTabLettre });
        this.noQuestion++;
    }

    render() {
        return <React.Fragment><Helmet>
            <title>{(this.type === 'vocabulaire' ? "Test pour apprendre le vocabulaire anglais : " : "Test de grammaire et vocabulaire anglais : ") + this.dataJeu.titre}  </title>
            <meta name="description" content="Différents tests gratuits en ligne de vocabulaire pour apprendre de nouveaux mots anglais ou simplement réviser son vocabulaire." />
        </Helmet><div>

                {!this.state.afficheResultat ? <div>
                    <div className="espaceJeu">
                        <div className="espaceJeuLettre espaceGrilleLettre">
                            <div className="margeBas10 margeHaut10 grandeFont">{this.state.motQuestion.texte}</div>
                            {this.state.motQuestion.indic != null && <div className="indice">{this.state.motQuestion.indic}</div>}
                            <div><Saisie verbe={this.state.verbe} ref={this.child} change={this.change}></Saisie></div>

                            <Lettres type={this.type} tabLettres={this.state.tabLettresReponse}></Lettres>


                            {this.state.afficheMotMelange && <MotMelange tabLettres={this.tabMotsMelange}></MotMelange>}
                        </div>
                        <div className="espaceInfoLettre">
                            <h2>Les aides</h2>
                            <div>
                                {this.state.afficheBoutonAideLettre &&
                                    <span className="margeDroite30"><Button onClick={this.clicAideLettre}>Afficher une lettre</Button><span className="margeGauche">({this.state.aideLettre})</span></span>}
                                {this.state.afficheBoutonAideMelange &&
                                    <span><Button onClick={this.clicMotMelange}>Réponse mélangée</Button><span className="margeGauche">({this.state.aideMotMelange})</span></span>}

                            </div>
                            <div className='centre margeHaut30'>Nombre d'erreurs autorisées {this.state.fautes} / {this.nbErreursFaute}</div>
                        </div>
                        <div className="centre margeHaut30"><Button type='danger' onClick={this.clicPasser}>Je passe</Button></div>
                    </div>{this.type === 'vocabulaire' ? <React.Fragment> <div><Compteur noQuestion={this.state.questionEnCours + 1} nbQuestion={this.nbQuestion}></Compteur></div>
                        <h1 className="margeHaut30">{'Test de vocabulaire anglais : ' + this.dataJeu.titre}</h1><p>Écrire la traduction.</p></React.Fragment> :
                        <React.Fragment><h1 className="margeHaut30">{"Traduire des phrases simples : " + this.dataJeu.titre}</h1><p>Écrire la phrase traduite. Il faut absolument s'aider des petits carrés orange pour trouver la phrase attendue (forme contractée ou non). La traduction est une traduction parmi d'autres possibles. Nous n'acceptons pas plusieurs traductions. Pas de majuscule en début de phrase sauf pour I.</p></React.Fragment>}

                </div> : (this.data.concours ? <FinEtape nom={this.nom} donneesJeu={this.data.jeu} nbFautes={this.listeFaute.length}></FinEtape> : <Resultat langue={this.langue} type={this.type} titre={this.dataJeu.titre} nom={this.nom} id={this.id} listeFaute={this.listeFaute} donnees={this.data}></Resultat>)}</div>
        </React.Fragment>

    }
}